import React from 'react';

const MaximizeIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5858 4L13.2929 9.29289C12.9024 9.68342 12.9024 10.3166 13.2929 10.7071C13.6834 11.0976 14.3166 11.0976 14.7071 10.7071L20 5.41421V9C20 9.55228 20.4477 10 21 10C21.5523 10 22 9.55228 22 9V3.00098C22 3.00065 22 3.00033 22 3C22 2.984 21.9996 2.96801 21.9989 2.95203C21.9874 2.71232 21.8902 2.47595 21.7071 2.29289C21.6112 2.19702 21.5007 2.12468 21.3828 2.07588C21.2649 2.02699 21.1356 2 21 2H20.9998H15C14.4477 2 14 2.44772 14 3C14 3.55228 14.4477 4 15 4H18.5858ZM5.41421 20L10.7071 14.7071C11.0976 14.3166 11.0976 13.6834 10.7071 13.2929C10.3166 12.9024 9.68342 12.9024 9.29289 13.2929L4 18.5858V15C4 14.4477 3.55228 14 3 14C2.44772 14 2 14.4477 2 15V20.9998V21C2 21.1356 2.02699 21.2649 2.07588 21.3828C2.12468 21.5007 2.19702 21.6112 2.29289 21.7071C2.47595 21.8902 2.71232 21.9874 2.95203 21.9989C2.96801 21.9996 2.984 22 3 22H3.00098H9C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20H5.41421Z"
      />
    </svg>
  );
};

export default MaximizeIcon;
