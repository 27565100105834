// @ts-check
import React from 'react';

const ChatFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M24 5v14h-4.001L14 23v-4H4V5h20zm-4-2H2.222v10H0V1h20v2zM8 10.999h8v-2H8v2zM8 15h12v-2H8v2z" />
    </svg>
  );
};

export default ChatFilledIcon;
