// @ts-check
import React from 'react';

const SettingsIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M20.892,13.325 L19.09,14.16 C18.949,14.623 18.764,15.066 18.54,15.485 L19.225,17.351 C18.695,18.064 18.064,18.695 17.351,19.225 L15.485,18.54 C15.066,18.764 14.623,18.949 14.16,19.09 L13.325,20.892 C12.892,20.956 12.451,21 12,21 C11.549,21 11.108,20.956 10.675,20.892 L9.84,19.09 C9.377,18.949 8.934,18.764 8.515,18.54 L6.649,19.225 C5.936,18.695 5.305,18.064 4.775,17.351 L5.46,15.485 C5.236,15.066 5.051,14.623 4.91,14.16 L3.108,13.325 C3.044,12.892 3,12.451 3,12 C3,11.549 3.044,11.108 3.108,10.675 L4.91,9.84 C5.051,9.377 5.236,8.934 5.46,8.515 L4.775,6.649 C5.305,5.935 5.936,5.305 6.649,4.775 L8.515,5.46 C8.934,5.236 9.377,5.051 9.84,4.91 L10.675,3.108 C11.108,3.044 11.549,3 12,3 C12.451,3 12.892,3.044 13.325,3.108 L14.16,4.91 C14.623,5.051 15.066,5.236 15.485,5.46 L17.351,4.775 C18.064,5.305 18.695,5.935 19.225,6.649 L18.54,8.515 C18.764,8.934 18.949,9.377 19.09,9.84 L20.892,10.675 C20.956,11.108 21,11.549 21,12 C21,12.451 20.956,12.892 20.892,13.325 Z M12,15.706 C14.043,15.706 15.706,14.043 15.706,12 C15.706,9.956 14.043,8.294 12,8.294 C9.957,8.294 8.294,9.956 8.294,12 C8.294,14.043 9.957,15.706 12,15.706 Z" />
    </svg>
  );
};

export default SettingsIcon;
