import React from 'react';

const OverflowVerticalIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M10,14.001 L10,10.001 L14,10.001 L14,14.001 L10,14.001 Z M10,20.001 L10,16 L14,16 L14,20.001 L10,20.001 Z M10,8 L10,4 L14,4 L14,8 L10,8 Z" />
    </svg>
  );
};

export default OverflowVerticalIcon;
