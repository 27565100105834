import React from 'react';

const NumberIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        d="M17 7H20C21.1046 7 22 7.89543 22 9V12M17 17H20C21.1046 17 22 16.1046 22 15V12M22 12H18.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 7H12C13.1046 7 14 7.89543 14 9V10C14 11.1046 13.1046 12 12 12H11C9.89543 12 9 12.8954 9 14V17H14"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 9L4 7V17M4 17H6M4 17H2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberIcon;
