import React from 'react';

const UploadIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11C3 7.68629 5.68629 5 9 5C11.6648 5 14.0977 6.84673 14.7863 9.41584C14.9282 9.94538 15.47 10.2619 16.001 10.1255C16.3193 10.0438 16.6538 10 17 10C19.2091 10 21 11.7909 21 14C21 15.3078 20.3734 16.469 19.3998 17.2005C18.9583 17.5322 18.8693 18.1591 19.201 18.6007C19.5327 19.0422 20.1596 19.1312 20.6012 18.7995C22.0559 17.7065 23 15.9634 23 14C23 10.6863 20.3137 8 17 8C16.8073 8 16.6164 8.00912 16.428 8.02698C15.2314 5.03978 12.2511 3 9 3C4.58172 3 1 6.58172 1 11C1 13.2383 1.92053 15.2634 3.40112 16.7142C3.79558 17.1008 4.42871 17.0944 4.81526 16.6999C5.2018 16.3054 5.19538 15.6723 4.80092 15.2858C3.6884 14.1956 3 12.6791 3 11ZM7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L11 14.4142V20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20V14.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L12.7078 11.2936L12.7005 11.2864C12.6062 11.1937 12.498 11.1236 12.3828 11.0759C12.2649 11.027 12.1356 11 12 11C11.8644 11 11.7351 11.027 11.6172 11.0759C11.502 11.1236 11.3938 11.1937 11.2995 11.2864L11.2922 11.2936L7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071Z"
      />
    </svg>
  );
};

export default UploadIcon;
