import React from 'react';

const ExternalLinkIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 4.44772 4.44772 4 5 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H5C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V13C22 12.4477 21.5523 12 21 12C20.4477 12 20 12.4477 20 13V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5ZM22 3.00001C22 2.44772 21.5523 2.00001 21 2.00001H15C14.4477 2.00001 14 2.44772 14 3.00001C14 3.55229 14.4477 4.00001 15 4.00001H18.5858L13.2929 9.29289C12.9024 9.68341 12.9024 10.3166 13.2929 10.7071C13.6835 11.0976 14.3166 11.0976 14.7072 10.7071L20 5.41423L20 9C20 9.55229 20.4477 10 21 10C21.5523 10 22 9.55228 22 9L22 3.00001Z"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
