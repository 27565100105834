// @ts-check
import React from 'react';

const FolderIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M2 4h7.999L12 6h10v14H2V4zm2 2v12h15.999V8.001h-8.828l-.586-.586L9.171 6H4z" />
    </svg>
  );
};

export default FolderIcon;
