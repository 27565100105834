// @ts-check
import React from 'react';

const FlagDeIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fill="#F0F0F0"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      />
      <path
        fill="#0052B4"
        d="M4.067 5.912a9.964 9.964 0 0 0-1.723 3.48h5.203l-3.48-3.48zM21.656 9.391a9.966 9.966 0 0 0-1.723-3.48l-3.48 3.48h5.203zM2.344 14.609a9.964 9.964 0 0 0 1.723 3.48l3.48-3.48H2.344zM18.088 4.067a9.964 9.964 0 0 0-3.48-1.723v5.203l3.48-3.48zM5.912 19.933a9.964 9.964 0 0 0 3.48 1.722v-5.202l-3.48 3.48zM9.391 2.344a9.964 9.964 0 0 0-3.48 1.723l3.48 3.48V2.344zM14.609 21.655a9.962 9.962 0 0 0 3.48-1.722l-3.48-3.48v5.202zM16.453 14.609l3.48 3.48a9.966 9.966 0 0 0 1.723-3.48h-5.203z"
      />
      <path
        fill="#D80027"
        d="M21.915 10.696h-8.61V2.085a10.1 10.1 0 0 0-2.61 0v8.61h-8.61a10.1 10.1 0 0 0 0 2.61h8.61v8.61a10.115 10.115 0 0 0 2.61 0v-8.61h8.61a10.115 10.115 0 0 0 0-2.61z"
      />
      <path
        fill="#D80027"
        d="M14.609 14.609l4.462 4.462c.205-.205.401-.42.588-.642l-3.82-3.82h-1.23zM9.391 14.609L4.93 19.07c.205.205.42.401.642.588l3.82-3.82v-1.23zM9.391 9.391L4.93 4.93c-.205.205-.401.42-.588.642l3.82 3.82h1.23zM14.609 9.391L19.07 4.93a9.982 9.982 0 0 0-.642-.588l-3.82 3.82v1.23z"
      />
    </svg>
  );
};

export default FlagDeIcon;
