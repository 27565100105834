// @ts-check
import React from 'react';

const ClearCircle = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 2c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8zm-.007 6.592L14.586 8 16 9.414l-2.59 2.59L16 14.586 14.586 16l-2.59-2.581L9.414 16 8 14.586l2.579-2.579L8 9.436l1.414-1.414 2.579 2.57z" />
    </svg>
  );
};

export default ClearCircle;
