import React from 'react';

const CommunicationSmallIcon = (props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
      <path d="M1 2H11V9H8.9995L6 11V9H1V2ZM2 3V8H7V9.131L8.697 8H10V3H2ZM3 4.9995V3.9995H7V4.9995H3ZM3 6.9965V5.9965H9V6.9965H3Z" />
    </svg>
  );
};

export default CommunicationSmallIcon;
