import React from 'react';

const SumIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12536 3.51523C5.30158 3.19728 5.63648 3 6 3H18C18.5523 3 19 3.44772 19 4V6.66667C19 7.21895 18.5523 7.66667 18 7.66667C17.4477 7.66667 17 7.21895 17 6.66667V5H7.80425L11.848 11.47C12.0507 11.7943 12.0507 12.2057 11.848 12.53L7.80425 19H17V17.3333C17 16.781 17.4477 16.3333 18 16.3333C18.5523 16.3333 19 16.781 19 17.3333V20C19 20.5523 18.5523 21 18 21H6C5.63648 21 5.30158 20.8027 5.12536 20.4848C4.94913 20.1668 4.95933 19.7783 5.152 19.47L9.82075 12L5.152 4.53C4.95933 4.22173 4.94913 3.83319 5.12536 3.51523Z"
      />
    </svg>
  );
};

export default SumIcon;
