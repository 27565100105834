import React from 'react';

const OutgoingIcon16 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99902 2.00005C1.99902 1.61345 2.31242 1.30005 2.69902 1.30005H5.69902C6.08562 1.30005 6.39902 1.61345 6.39902 2.00005C6.39902 2.38665 6.08562 2.70005 5.69902 2.70005H3.39902V13.3H5.69902C6.08562 13.3 6.39902 13.6135 6.39902 14C6.39902 14.3866 6.08562 14.7 5.69902 14.7H2.69902C2.31242 14.7 1.99902 14.3866 1.99902 14V2.00005ZM10.5048 4.50507C10.7782 4.23171 11.2214 4.23171 11.4948 4.50507L14.4948 7.50507C14.7681 7.77844 14.7681 8.22166 14.4948 8.49502L11.4948 11.495C11.2214 11.7684 10.7782 11.7684 10.5048 11.495C10.2315 11.2217 10.2315 10.7784 10.5048 10.5051L12.3099 8.70005H5.99902C5.61242 8.70005 5.29902 8.38665 5.29902 8.00005C5.29902 7.61345 5.61242 7.30005 5.99902 7.30005H12.3099L10.5048 5.49502C10.2315 5.22166 10.2315 4.77844 10.5048 4.50507Z"
      />
    </svg>
  );
};

export default OutgoingIcon16;
