// @ts-check
import React from 'react';

const ThinkOwlLogo = (props) => {
  return (
    <svg width="78" height="16" viewBox="0 0 78 16" {...props}>
      <path
        d="M77.7374 13.4318V2.77277H76.8461V13.4318H77.7374ZM72.4758 13.4318L74.9437 5.21817H74.0525L72.0473 12.0715L69.905 5.21817H69.0309L66.8885 12.0542L64.9003 5.21817H63.9406L66.4086 13.4318H67.2312L69.4421 6.50967L71.6532 13.4318H72.4758Z"
        fill="#169A92"
      />
      <path
        d="M25.1006 13.4318H25.9917V7.45661C26.2831 6.99169 27.2943 5.83799 28.5111 5.83799C29.7453 5.83799 30.1908 6.59564 30.1908 7.80101V13.4318H31.0821V7.61158C31.0821 6.13072 30.3965 5.02868 28.6997 5.02868C27.3287 5.02868 26.3859 5.95853 25.9917 6.52676V2.77267H25.1006V13.4318ZM34.5097 4.12848C34.8867 4.12848 35.1952 3.81853 35.1952 3.42248C35.1952 3.02644 34.8867 2.7337 34.5097 2.7337C34.1498 2.7337 33.8412 3.02644 33.8412 3.42248C33.8412 3.81853 34.1498 4.12848 34.5097 4.12848ZM34.0641 13.4318H34.9724V5.21809H34.0641V13.4318ZM38.0399 13.4318H38.9312V7.45661C39.4454 6.68174 40.4223 5.83799 41.4506 5.83799C42.6846 5.83799 43.1131 6.59564 43.1131 7.80101V13.4318H44.0044V7.61158C44.0044 6.13072 43.336 5.02868 41.6392 5.02868C40.4909 5.02868 39.5311 5.70024 38.9312 6.52676V5.21809H38.0399V13.4318ZM51.6653 13.4318H52.7792L48.9745 8.81695L52.5907 5.21809H51.4768L47.8947 8.81695L51.6653 13.4318ZM46.9178 13.4318H47.809V2.77267H46.9178V13.4318Z"
        fill="#8594A2"
      />
      <path d="M23.706 3.6291V2.77277H16.7828V3.6291H23.706Z" fill="#8594A2" />
      <path d="M19.7904 13.4209H20.6987V3.24707H19.7904V13.4209Z" fill="#8594A2" />
      <path
        d="M58.5532 13.4208C61.2537 13.4208 62.9073 10.921 62.9073 8.06168C62.9073 5.20238 61.2401 2.7337 58.5532 2.7337C55.8392 2.7337 54.1991 5.23346 54.1991 8.09276C54.1991 10.9521 55.8527 13.4208 58.5532 13.4208ZM58.5532 12.7081C56.287 12.7081 54.9706 10.5325 54.9706 8.06168C54.9706 5.59087 56.3005 3.44643 58.5532 3.44643C60.8059 3.44643 62.1357 5.62196 62.1357 8.09276C62.1357 10.5636 60.8059 12.7081 58.5532 12.7081Z"
        fill="#169A92"
      />
      <path
        d="M5.86121 4.28027L5.86359 5.31002C5.8704 5.52379 6.04171 5.69153 6.25402 5.69153C6.25429 5.69153 6.25452 5.69146 6.25478 5.69146C6.25503 5.69146 6.25528 5.69153 6.25555 5.69153C6.46784 5.69153 6.63917 5.52379 6.64598 5.31002L6.64838 4.28027C6.55443 4.38025 6.41303 4.44392 6.25479 4.44392C6.09654 4.44392 5.95514 4.3802 5.86121 4.28027Z"
        fill="#76808A"
      />
      <path
        d="M12.1268 1.38878C12.6474 0.693199 12.4883 0.081545 12.4815 0.0560207L12.4658 0L12.413 0.0231334C12.4046 0.0270118 11.5378 0.386742 9.00072 0.390119C8.99186 0.389659 8.94377 0.389361 8.92863 0.389361C7.81295 0.389361 6.79665 1.01516 6.25498 2.0298C5.7134 1.01516 4.69759 0.389361 3.58191 0.389361C3.56678 0.389361 3.52148 0.389659 3.51464 0.390119C0.974694 0.387202 0.104851 0.0270118 0.0965656 0.0231334L0.0437637 0L0.0280877 0.0560207C0.0212498 0.081545 -0.137812 0.693199 0.383299 1.38829C0.580517 1.65274 0.834472 1.8278 0.970017 1.90941C0.674924 2.40733 0.51931 2.9688 0.51931 3.53667C0.51931 3.75239 0.693532 3.92744 0.908353 3.92744C1.12312 3.92744 1.29732 3.75239 1.29732 3.53667C1.29732 2.23234 2.32248 1.17103 3.58191 1.17103C4.84097 1.17103 5.86557 2.23234 5.86557 3.53667C5.86557 3.75239 6.03977 3.92744 6.25498 3.92744C6.46984 3.92744 6.64404 3.75239 6.64404 3.53667C6.64404 2.23234 7.66908 1.17103 8.92863 1.17103C10.1876 1.17103 11.2122 2.23234 11.2122 3.53667C11.2122 3.75239 11.3864 3.92744 11.6012 3.92744C11.8161 3.92744 11.9902 3.75239 11.9902 3.53667C11.9902 2.96933 11.8347 2.4078 11.5401 1.90941C11.6747 1.82883 11.9276 1.65513 12.1268 1.38878Z"
        fill="#109A92"
      />
      <path
        d="M11.9315 8.98408C12.0763 8.4727 12.1146 7.99032 11.996 7.56331C11.8717 7.11663 11.7466 6.6792 11.5485 6.27376C10.5061 7.16076 8.64166 7.97252 5.71143 6.72807C3.19126 5.67411 1.73418 6.24748 0.967222 7.29309C-0.106891 8.68005 0.660829 11.1454 3.34539 13.3808C5.28271 14.9931 6.41294 16 6.41294 16C6.41294 16 7.04158 15.4595 7.87853 14.6282C6.58389 13.805 5.60029 12.4714 5.60029 12.4714C6.87531 13.1603 7.94298 13.5103 8.807 13.667C9.3226 13.1116 9.85693 12.495 10.3408 11.8558C8.40255 11.9656 6.76602 10.6367 6.76602 10.6367C10.4874 11.0966 11.599 9.81282 11.9315 8.98408Z"
        fill="#239C96"
      />
      <path
        d="M3.58134 3.84652C3.26673 3.84652 3.01221 3.59081 3.01221 3.27464C3.01221 2.95852 3.26673 2.70184 3.58134 2.70184C3.89604 2.70184 4.15057 2.95852 4.15057 3.27464C4.15057 3.59081 3.89604 3.84652 3.58134 3.84652Z"
        fill="#76808A"
      />
      <path
        d="M8.92836 3.84652C8.61368 3.84652 8.35913 3.59081 8.35913 3.27464C8.35913 2.95852 8.61368 2.70184 8.92836 2.70184C9.24295 2.70184 9.49752 2.95852 9.49752 3.27464C9.49752 3.59081 9.24295 3.84652 8.92836 3.84652Z"
        fill="#76808A"
      />
    </svg>
  );
};

export default ThinkOwlLogo;
