import React from 'react';

const DocumentNewIcon = (props) => {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13345 13.2V2.79995H7.86678V5.99995C7.86678 6.44178 8.22495 6.79995 8.66678 6.79995H11.8668V13.2H4.13345ZM11.1621 5.19995L9.46678 3.45617V5.19995H11.1621ZM4.00011 1.19995C3.1901 1.19995 2.53345 1.8566 2.53345 2.66662V13.3333C2.53345 14.1433 3.1901 14.8 4.00011 14.8H12.0001C12.8101 14.8 13.4668 14.1433 13.4668 13.3333V5.87061C13.4668 5.48878 13.3179 5.122 13.0517 4.84823L9.93671 1.64424C9.66057 1.3602 9.28127 1.19995 8.88512 1.19995H4.00011ZM6.00011 7.86662C5.55829 7.86662 5.20011 8.22479 5.20011 8.66662C5.20011 9.10845 5.55829 9.46662 6.00011 9.46662H10.0001C10.4419 9.46662 10.8001 9.10845 10.8001 8.66662C10.8001 8.22479 10.4419 7.86662 10.0001 7.86662L6.00011 7.86662ZM5.20011 11.3333C5.20011 10.8915 5.55829 10.5333 6.00011 10.5333L10.0001 10.5333C10.4419 10.5333 10.8001 10.8915 10.8001 11.3333C10.8001 11.7751 10.4419 12.1333 10.0001 12.1333H6.00011C5.55829 12.1333 5.20011 11.7751 5.20011 11.3333Z"
      />
    </svg>
  );
};

export default DocumentNewIcon;
