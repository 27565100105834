import React from 'react';

const EditFileIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M2,3 L12,3 L15.418,6.417 L14,7.836 L11.179,7.836 L11.171,5 L4,5 L4,19 L14,19 L14,16.164 L16,14.165 L16,21 L2,21 L2,3 Z M8,15.25 L15.949,7.3 L18.7,10.051 L10.75,18 L8,18 L8,15.25 Z M17.0489,6.2004 L19.2499,3.9994 L21.9999,6.7504 L19.7999,8.9504 L17.0489,6.2004 Z" />
    </svg>
  );
};

export default EditFileIcon;
