import { theme } from 'jsStyles/theme';
import React from 'react';

const SignaturePenBaseColorIcon = ({
  color,
  className = ''
}: {
  color?: string;
  className?: string;
}) => {
  return (
    <svg width="32" height="72" viewBox="0 0 32 72" className={className}>
      <path d="M0 0H32V56L16 72L0 56V0Z" fill={color || theme.colors.text2} />
      <path
        d="M23.0496 22.2004L25.2506 19.9994L27.9996 22.7504L25.7996 24.9504L23.0496 22.2004ZM21.9492 23.3003L24.6992 26.0503L16.7502 34.0003H14.0002V31.2493L21.9492 23.3003Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49504 23.5C8.75002 23.4975 8.96604 23.6873 8.99642 23.9404L10.2802 34.6384L11.5431 31.7969C11.6233 31.6164 11.8024 31.5 12 31.5H13.5V32.5H12.3249L10.4569 36.7031C10.3655 36.9087 10.1481 37.0279 9.92556 36.9944C9.70307 36.9609 9.53035 36.783 9.50354 36.5596L8.54517 28.5731L7.9975 34.0498C7.97535 34.2713 7.80942 34.4515 7.59048 34.4917C7.37153 34.532 7.15233 34.4227 7.05277 34.2236L4.82916 29.7764L5.72359 29.3292L7.17441 32.2308L8.00246 23.9502C8.02784 23.6965 8.24007 23.5025 8.49504 23.5Z"
        fill="white"
      />
    </svg>
  );
};

export default SignaturePenBaseColorIcon;
