import React from 'react';

const FlagIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M5 21V13.7M5 13.7V3.9C5.4375 3.6 6.75 3 8.5 3C10.6875 3 13.3125 4.8 15.5 4.8C17.25 4.8 18.5625 4.2 19 3.9V13.7C18.5625 14 17.6875 14.6 15.5 14.6C13.3125 14.6 10.6875 12.8 8.5 12.8C6.3125 12.8 5.14583 13.4 5 13.7Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlagIcon;
