// @ts-check
import React from 'react';

const FlagDeIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path fill="#FFDA44" d="M2.622 15.478a10.004 10.004 0 0 0 18.756 0L12 14.608l-9.378.87z" />
      <path
        fill="#000"
        d="M12 2C7.7 2 4.035 4.714 2.622 8.522l9.378.87 9.378-.87A10.004 10.004 0 0 0 12 2z"
      />
      <path
        fill="#D80027"
        d="M2.622 8.522A9.979 9.979 0 0 0 2 12c0 1.223.22 2.395.622 3.478h18.756A9.977 9.977 0 0 0 22 12c0-1.223-.22-2.395-.622-3.478H2.622z"
      />
    </svg>
  );
};

export default FlagDeIcon;
