import React from 'react';

const RotateIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M9.49,5.091 L9.49,2 L13.491,5.984 L12.001,7.467 L9.49,9.966 L9.49,6.97 C5.849,6.97 2.886,9.92 2.886,13.546 C2.886,17.172 5.849,20.121 9.49,20.121 C10.637,20.121 11.717,19.827 12.658,19.313 L14.032,20.681 C12.718,21.512 11.163,22 9.49,22 C4.802,22 1,18.215 1,13.546 C1,8.876 4.802,5.091 9.49,5.091 Z M9.0494,13.0587 L15.0114,7.1227 L22.9994,15.0767 L17.0374,21.0127 L9.0494,13.0587 Z M11.7174,13.0587 L17.0374,18.3567 L20.3314,15.0767 L15.0114,9.7787 L11.7174,13.0587 Z" />
    </svg>
  );
};

export default RotateIcon;
