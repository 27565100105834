import React from 'react';

const ModuleTestIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3C11 2.44772 10.5523 2 10 2C9.44771 2 9 2.44772 9 3V4H7C5.34315 4 4 5.34315 4 7V9H3C2.44772 9 2 9.44772 2 10C2 10.5523 2.44772 11 3 11H4V12.5997C2.21916 13.5101 1 15.3627 1 17.5C1 20.5376 3.46243 23 6.5 23C8.63734 23 10.4899 21.7808 11.4003 20H13V21C13 21.5523 13.4477 22 14 22C14.5523 22 15 21.5523 15 21V20H17C18.6569 20 20 18.6569 20 17V15H21C21.5523 15 22 14.5523 22 14C22 13.4477 21.5523 13 21 13H20V11H21C21.5523 11 22 10.5523 22 10C22 9.44772 21.5523 9 21 9H20V7C20 5.34315 18.6569 4 17 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H11V3ZM11.9776 18H17C17.5523 18 18 17.5523 18 17V15V13V11V9V7C18 6.44772 17.5523 6 17 6H7C6.44772 6 6 6.44772 6 7V9V11V12.0224C6.16468 12.0076 6.33146 12 6.5 12C7.02001 12 7.52317 12.0722 8 12.207V9C8 8.44772 8.44772 8 9 8H15C15.5523 8 16 8.44772 16 9V15C16 15.5523 15.5523 16 15 16H11.793C11.9278 16.4768 12 16.98 12 17.5C12 17.6685 11.9924 17.8353 11.9776 18ZM10.7428 14H14V10H10V13.2572C10.2706 13.4806 10.5194 13.7294 10.7428 14ZM5.265 14.576C5.11087 14.4797 4.91659 14.4746 4.75762 14.5627C4.59864 14.6508 4.5 14.8182 4.5 15V20C4.5 20.1818 4.59864 20.3492 4.75762 20.4373C4.91659 20.5254 5.11087 20.5203 5.265 20.424L9.265 17.924C9.41119 17.8326 9.5 17.6724 9.5 17.5C9.5 17.3276 9.41119 17.1674 9.265 17.076L5.265 14.576Z"
      />
    </svg>
  );
};

export default ModuleTestIcon;
