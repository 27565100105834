import React from 'react';

const TableIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5C2 3.34315 3.34315 2 5 2H12H19C20.6569 2 22 3.34315 22 5V12V19C22 20.6569 20.6569 22 19 22H12H5C3.34315 22 2 20.6569 2 19V12V5ZM5 4C4.44772 4 4 4.44772 4 5V11H11V4H5ZM13 4V11H20V5C20 4.44772 19.5523 4 19 4H13ZM20 13H13V20H19C19.5523 20 20 19.5523 20 19V13ZM11 20V13H4V19C4 19.5523 4.44772 20 5 20H11Z"
      />
    </svg>
  );
};

export default TableIcon;
