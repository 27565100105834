import React from 'react';

const PrintIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V8H4C2.89543 8 2 8.89543 2 10V17C2 18.1046 2.89543 19 4 19H6V21C6 21.5523 6.44772 22 7 22H17C17.5523 22 18 21.5523 18 21V19H20C21.1046 19 22 18.1046 22 17V10C22 8.89543 21.1046 8 20 8H19V3C19 2.44772 18.5523 2 18 2H6ZM17 8V4H7V8H17ZM6 16V17H4V10H20V17H18V16C18 15.4477 17.5523 15 17 15H7C6.44772 15 6 15.4477 6 16ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H6.01C6.56228 13 7.01 12.5523 7.01 12C7.01 11.4477 6.56228 11 6.01 11H6ZM8 17V20H16V17H8Z"
      />
    </svg>
  );
};

export default PrintIcon;
