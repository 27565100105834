import React from 'react';

const DesktopIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V14C22 15.6569 20.6569 17 19 17H13V19H17C17.5523 19 18 19.4477 18 20C18 20.5523 17.5523 21 17 21H12H7C6.44772 21 6 20.5523 6 20C6 19.4477 6.44772 19 7 19H11V17H5C3.34315 17 2 15.6569 2 14V6ZM19 15H13H11H5C4.44772 15 4 14.5523 4 14V6C4 5.44772 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6V14C20 14.5523 19.5523 15 19 15Z"
      />
    </svg>
  );
};

export default DesktopIcon;
