import React from 'react';

const DocumentRemoveXIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C3.89543 2 3 2.89543 3 4V20C3 21.1046 3.89543 22 5 22H13C13.5523 22 14 21.5523 14 21C14 20.4477 13.5523 20 13 20H5V4L11 4V9.00003C11 9.55231 11.4477 10 12 10H17V12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12V8.80599C19 8.28531 18.7969 7.78515 18.434 7.41183L13.7615 2.60584C13.3849 2.21852 12.8677 2 12.3275 2H5ZM16.2164 8.00003L13 4.6917V8.00003H16.2164ZM8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14L14 14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12L8 12ZM12 18L8 18C7.44772 18 7 17.5523 7 17C7 16.4477 7.44771 16 8 16L12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18ZM15.6708 15.1705C16.0613 14.7799 16.6945 14.7799 17.085 15.1705L18.4995 16.5849L19.914 15.1705C20.3045 14.7799 20.9377 14.7799 21.3282 15.1705C21.7187 15.561 21.7187 16.1941 21.3282 16.5847L19.9137 17.9991L21.3277 19.4131C21.7182 19.8036 21.7182 20.4368 21.3277 20.8273C20.9372 21.2178 20.304 21.2178 19.9135 20.8273L18.4995 19.4134L17.0856 20.8273C16.695 21.2178 16.0619 21.2178 15.6713 20.8273C15.2808 20.4368 15.2808 19.8036 15.6713 19.4131L17.0853 17.9991L15.6708 16.5847C15.2803 16.1941 15.2803 15.561 15.6708 15.1705Z"
      />
    </svg>
  );
};

export default DocumentRemoveXIcon;
