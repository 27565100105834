import React from 'react';

const PaymentIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M2,20 L2,4 L22,4 L22,20 L2,20 Z M4,18 L20,18 L20,12 L4,12 L4,18 Z M4,8.001 L20,8.001 L20,6 L4,6 L4,8.001 Z" />
    </svg>
  );
};

export default PaymentIcon;
