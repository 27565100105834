import React from 'react';

const CheckCircleIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12 2C17.5229 2 22 6.47714 22 12C22 17.5229 17.5229 22 12 22C6.47714 22 2 17.5229 2 12C2 6.47714 6.47714 2 12 2ZM12 4C7.58857 4 4 7.58857 4 12C4 16.4114 7.58857 20 12 20C16.4114 20 20 16.4114 20 12C20 7.58857 16.4114 4 12 4ZM14.58 9L15.994 10.414L10.936 15.472L8 12.535L9.414 11.12L10.936 12.644L14.58 9Z" />
    </svg>
  );
};

export default CheckCircleIcon;
