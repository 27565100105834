import React from 'react';

const ImageIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M3,21 L21,21 L21,3 L3,3 L3,21 Z M14.191,12.71 L18.999,19 L5.235,19 L8.88,14.23 L10.954,16.945 L14.191,12.71 Z" />
    </svg>
  );
};

export default ImageIcon;
