import React from 'react';

const FileeeSignet40Icon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" {...props}>
      <path d="M0 0V40H40V0H0ZM24.7149 5.2634L27.763 7.39781L18.9918 8.94456L18.3092 6.39333L24.7149 5.2634ZM20.3628 34.7366L11.9398 22.8248L14.8947 22.4344L22.8562 33.6966L20.3628 34.7366ZM18.121 22.0085L20.7358 21.6635L22.7679 33.1907L19.1263 27.7094L18.121 22.0085ZM11.7797 22.5979L12.9724 19.7198L17.5738 18.9043L15.914 9.49013L18.0509 6.43625L20.1688 18.4438L24.7678 17.6332L25.2242 20.2249L11.7797 22.5979ZM25.6228 10.4476L25.2498 8.34038L27.8084 7.65615L28.2211 9.9903L25.6228 10.4476Z" />
    </svg>
  );
};

export default FileeeSignet40Icon;
