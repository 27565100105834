import React from 'react';

const FileeeboxIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4C1.89543 4 1 4.89543 1 6V8C1 8.74028 1.4022 9.38663 2 9.73244V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V9.73244C22.5978 9.38663 23 8.74028 23 8V6C23 4.89543 22.1046 4 21 4H3ZM4 18V10H20V18H4ZM3 6H21V8H3V6ZM7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14H11C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12H7Z"
        fill="black"
      />
    </svg>
  );
};

export default FileeeboxIcon;
