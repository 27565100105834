// @ts-check
import React from 'react';

const DeleteIconOutline = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M6 7h12l-1.267 14h-9.46L6 7zm2.19 2L9.1 19h5.807l.905-10h-7.62zm1.584-6h4.46l1.64 1h3.127v2H5V4h3.126l1.648-1z" />
    </svg>
  );
};

export default DeleteIconOutline;
