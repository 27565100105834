import React from 'react';

const GoogleLogo = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M 17.183594 12.121094 C 17.183594 11.738281 17.148438 11.371094 17.085938 11.019531 L 12 11.019531 L 12 13.105469 L 14.90625 13.105469 C 14.78125 13.78125 14.402344 14.355469 13.828125 14.738281 L 13.828125 16.089844 L 15.574219 16.089844 C 16.59375 15.152344 17.183594 13.765625 17.183594 12.121094 Z M 17.183594 12.121094 "
        fill="#4285F4"
      />
      <path
        d="M 12 17.398438 C 13.457031 17.398438 14.679688 16.917969 15.574219 16.089844 L 13.828125 14.738281 C 13.34375 15.0625 12.726562 15.253906 12 15.253906 C 10.59375 15.253906 9.402344 14.300781 8.976562 13.027344 L 7.175781 13.027344 L 7.175781 14.425781 C 8.0625 16.191406 9.890625 17.398438 12 17.398438 Z M 12 17.398438 "
        fill="#34A853"
      />
      <path
        d="M 8.976562 13.027344 C 8.871094 12.703125 8.808594 12.355469 8.808594 12 C 8.808594 11.644531 8.871094 11.296875 8.976562 10.972656 L 8.976562 9.574219 L 7.175781 9.574219 C 6.808594 10.304688 6.601562 11.128906 6.601562 12 C 6.601562 12.871094 6.808594 13.695312 7.175781 14.425781 Z M 8.976562 13.027344 "
        fill="#FBBC05"
      />
      <path
        d="M 12 8.746094 C 12.792969 8.746094 13.503906 9.019531 14.0625 9.554688 L 15.613281 8.007812 C 14.679688 7.136719 13.457031 6.601562 12 6.601562 C 9.890625 6.601562 8.0625 7.808594 7.175781 9.574219 L 8.976562 10.972656 C 9.402344 9.699219 10.59375 8.746094 12 8.746094 Z M 12 8.746094 "
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleLogo;
