// @ts-check
import React from 'react';

const EmailIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M2,19 L22,19 L22,5 L2,5 L2,19 Z M6.585,6.992 L17.533,6.992 L12.006,10.788 L6.585,6.992 Z M4,7.617 L11.995,13.214 L20,7.717 L20,17.007 L4,17.007 L4,7.617 Z" />
    </svg>
  );
};

export default EmailIcon;
