// @ts-check
import React from 'react';

const MinusCircleOutline = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12,2 C17.5228571,2 22,6.47714286 22,12 C22,17.5228571 17.5228571,22 12,22 C6.47714286,22 2,17.5228571 2,12 C2,6.47714286 6.47714286,2 12,2 Z M12,4 C7.58857143,4 4,7.58857143 4,12 C4,16.4114286 7.58857143,20 12,20 C16.4114286,20 20,16.4114286 20,12 C20,7.58857143 16.4114286,4 12,4 Z M8,13 L8,11 L16,11 L16,13 L8,13 Z" />
    </svg>
  );
};

export default MinusCircleOutline;
