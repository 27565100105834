import React from 'react';

const ImageOutlineIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00005 4C4.44776 4 4.00005 4.44772 4.00005 5V13.5598L5.81862 11.1957C6.92277 9.76029 9.03729 9.62297 10.3178 10.9035L11.554 12.1397L13.8186 9.19568C14.9228 7.76029 17.0373 7.62297 18.3178 8.9035L20 10.5857V5C20 4.44772 19.5523 4 19 4H5.00005ZM10.3243 13.7384L8.9036 12.3177C8.47676 11.8909 7.77192 11.9366 7.40387 12.4151L4.00005 16.8401V19C4.00005 19.5523 4.44776 20 5.00005 20H5.50761L10.3243 13.7384ZM2.00005 16.5092V19C2.00005 20.6569 3.3432 22 5.00005 22H19C20.6569 22 22 20.6569 22 19V13.0009V12.999V5C22 3.34315 20.6569 2 19 2H5.00005C3.3432 2 2.00005 3.34315 2.00005 5V16.4892C1.99998 16.4959 1.99998 16.5026 2.00005 16.5092ZM19 20H8.03087L15.4039 10.4151C15.7719 9.93664 16.4768 9.89087 16.9036 10.3177L20 13.4142V19C20 19.5523 19.5523 20 19 20Z"
      />
    </svg>
  );
};

export default ImageOutlineIcon;
