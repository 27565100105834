import React from 'react';

const AnalysisIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path d="M5.951,3 L15.985,3 L20,6.981 L20,20.915 L11.93,20.915 L11.93,18.925 L17.993,18.925 L17.993,7.812 L15.162,7.812 L15.154,4.99 L7.958,4.99 L7.958,13.949 L5.951,13.949 L5.951,3 Z M7.067,17.0778 L8.01,15.0288 L8.953,17.0778 L11.02,18.0138 L8.953,18.9498 L8.01,20.9998 L7.067,18.9498 L5,18.0138 L7.067,17.0778 Z M14.2292,10.7139 L16.9892,11.9579 L14.2292,13.2009 L12.9752,15.9389 L11.7202,13.2009 L8.9612,11.9579 L11.7202,10.7139 L12.9752,7.9769 L14.2292,10.7139 Z" />
    </svg>
  );
};

export default AnalysisIcon;
