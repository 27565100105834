import React from 'react';

const RestoreIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C11.4477 20 11 20.4477 11 21C11 21.5523 11.4477 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.7262 3.0912 17.1969 4.85876 19H3C2.44772 19 2 19.4477 2 20C2 20.5523 2.44772 21 3 21H7.5C8.05228 21 8.5 20.5523 8.5 20V19.8151C8.50022 19.803 8.50022 19.7908 8.5 19.7787L8.5 15.5C8.5 14.9477 8.05229 14.5 7.5 14.5C6.94772 14.5 6.5 14.9477 6.5 15.5L6.5 17.8097C4.95944 16.3508 4 14.2871 4 12ZM12 6C12.5523 6 13 6.44772 13 7V11.3406L15.8939 12.5809C16.4015 12.7984 16.6367 13.3863 16.4191 13.8939C16.2016 14.4015 15.6137 14.6367 15.1061 14.4191L11.7273 12.9711C11.2861 12.782 11 12.3482 11 11.8681V7C11 6.44772 11.4477 6 12 6Z"
      />
    </svg>
  );
};

export default RestoreIcon;
