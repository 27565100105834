import React from 'react';

const CheckNoBoundsCircleFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C15.2673 2 18.1686 3.56696 19.9935 5.99038L12.0202 13.5147L9.70711 11.2016C9.31658 10.8111 8.68342 10.8111 8.29289 11.2016C7.90237 11.5921 7.90237 12.2253 8.29289 12.6158L11.2929 15.6158C11.6754 15.9983 12.2929 16.0073 12.6863 15.636L21.051 7.7424C21.6597 9.03417 22 10.4773 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      />
    </svg>
  );
};

export default CheckNoBoundsCircleFilledIcon;
