// @ts-check
import React from 'react';

const ChatIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M0 0h18v4h6v14h-4.001L14 22v-4H4v-5.294l-.399-.262H0V0zm2 2v8.444h2V4h12V2H2zm4 3.999v10h10v2.263l3.393-2.263H22v-10H6zm2 4v-2h8v2H8zm0 3.994v-2h12v2H8z" />
    </svg>
  );
};

export default ChatIcon;
