import React from 'react';

const CameraIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87108 3.8906C8.24201 3.3342 8.86648 3 9.53518 3H14.4648C15.1335 3 15.758 3.3342 16.1289 3.8906L17.5352 6H21C22.1046 6 23 6.89543 23 8V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V8C1 6.89543 1.89543 6 3 6H6.46482L7.87108 3.8906ZM14.4648 5H9.53518L8.12892 7.1094C7.75799 7.6658 7.13352 8 6.46482 8H3V19H21V8H17.5352C16.8665 8 16.242 7.6658 15.8711 7.1094L14.4648 5ZM4 10C4 9.44772 4.44772 9 5 9H5.01C5.56228 9 6.01 9.44772 6.01 10C6.01 10.5523 5.56228 11 5.01 11H5C4.44772 11 4 10.5523 4 10ZM9 13C9 11.3431 10.3431 10 12 10C13.6569 10 15 11.3431 15 13C15 14.6569 13.6569 16 12 16C10.3431 16 9 14.6569 9 13ZM12 8C9.23858 8 7 10.2386 7 13C7 15.7614 9.23858 18 12 18C14.7614 18 17 15.7614 17 13C17 10.2386 14.7614 8 12 8Z"
      />
    </svg>
  );
};

export default CameraIcon;
