import React from 'react';

const WarningIconRounded = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.89722C11.4844 1.89722 10.9775 2.0301 10.5282 2.28306C10.0794 2.53578 9.69816 2.90484 9.43215 3.34609L0.962153 17.4861L0.95403 17.4999C0.692083 17.9535 0.55348 18.4678 0.552014 18.9917C0.550547 19.5155 0.686267 20.0306 0.94567 20.4857C1.20507 20.9408 1.57912 21.32 2.03059 21.5857C2.48206 21.8513 2.99522 21.9942 3.51903 22L20.47 22L20.481 21.9999C21.0048 21.9941 21.518 21.8513 21.9694 21.5857C22.4209 21.32 22.795 20.9408 23.0544 20.4857C23.3138 20.0306 23.4495 19.5155 23.448 18.9917C23.4466 18.4678 23.308 17.9535 23.046 17.4999L23.0379 17.4861L14.5679 3.34608L14.565 3.34133C14.2976 2.90049 13.9211 2.53601 13.4718 2.28306C13.0225 2.0301 12.5156 1.89722 12 1.89722ZM12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8ZM12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18H12.01C12.5623 18 13.01 17.5523 13.01 17C13.01 16.4477 12.5623 16 12.01 16H12Z"
      />
    </svg>
  );
};

export default WarningIconRounded;
