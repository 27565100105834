// @ts-check
import React from 'react';

const ShareIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4C16.8954 4 16 4.89543 16 6C16 6.3099 16.0705 6.60334 16.1963 6.86516C16.2135 6.89424 16.2294 6.92446 16.2438 6.95578C16.2456 6.95961 16.2473 6.96345 16.2491 6.96729C16.59 7.58308 17.2463 8 18 8C19.1046 8 20 7.10457 20 6C20 4.89543 19.1046 4 18 4ZM14.9911 8.63569C15.7242 9.47198 16.8005 10 18 10C20.2091 10 22 8.20914 22 6C22 3.79086 20.2091 2 18 2C15.7909 2 14 3.79086 14 6C14 6.29123 14.0311 6.5752 14.0902 6.84875L8.90023 9.24523C8.17144 8.47821 7.14157 8 6 8C3.79086 8 2 9.79086 2 12C2 14.2091 3.79086 16 6 16C7.13883 16 8.1665 15.5241 8.89498 14.7603L14.078 17.2099C14.0268 17.4653 14 17.7295 14 18C14 20.2091 15.7909 22 18 22C20.2091 22 22 20.2091 22 18C22 15.7909 20.2091 14 18 14C16.7785 14 15.6849 14.5475 14.9512 15.4105L9.87148 13.0097C9.95536 12.6871 10 12.3488 10 12C10 11.6542 9.95613 11.3187 9.87364 10.9987L14.9911 8.63569ZM7.69888 10.9441C7.71657 10.9789 7.73606 11.0122 7.75716 11.044C7.912 11.328 8 11.6537 8 12C8 12.3464 7.91196 12.6721 7.75704 12.9562C7.73602 12.9879 7.71661 13.0211 7.69897 13.0557C7.34593 13.6227 6.71703 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C6.71697 10 7.34583 10.3773 7.69888 10.9441ZM16 18C16 17.6973 16.0672 17.4103 16.1876 17.1532C16.195 17.1393 16.202 17.1252 16.2088 17.1109L16.2173 17.0925C16.548 16.444 17.2222 16 18 16C19.1046 16 20 16.8954 20 18C20 19.1046 19.1046 20 18 20C16.8954 20 16 19.1046 16 18Z"
      />
    </svg>
  );
};

export default ShareIcon;
