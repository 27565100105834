import React from 'react';

const XingIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fill="#2C2A38"
        d="M4.727 5.35c-.194 0-.35.07-.433.201-.085.136-.09.294 0 .47L6.48 9.744c.003.007.003.016 0 .023l-3.414 5.94c-.089.176-.085.358 0 .494.082.13.216.224.41.224h3.231c.482 0 .7-.335.865-.628l3.482-6.053c-.013-.02-2.208-3.81-2.208-3.81-.16-.282-.416-.584-.91-.584H4.727zM18.256 1c-.481 0-.69.299-.862.604l-7.167 12.52c.011.022 4.576 8.272 4.576 8.272.16.28.406.604.9.604h3.217c.194 0 .345-.072.427-.203.086-.135.084-.314-.006-.49l-4.54-8.172c-.005-.007-.005-.015 0-.021l7.13-12.422c.09-.175.092-.354.007-.49-.082-.13-.234-.202-.428-.202h-3.254z"
      />
    </svg>
  );
};

export default XingIcon;
