import React from 'react';

const CheckFilled = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24  24" {...props}>
      <path d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z" />
      <path
        d="M7.70711 11.2929C7.31658 10.9024 6.68342 10.9024 6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L7.70711 11.2929ZM10.3333 15.3333L9.62623 16.0404C10.0168 16.431 10.6499 16.431 11.0404 16.0404L10.3333 15.3333ZM18.3738 8.70711C18.7643 8.31658 18.7643 7.68342 18.3738 7.29289C17.9832 6.90237 17.3501 6.90237 16.9596 7.29289L18.3738 8.70711ZM6.29289 12.7071L9.62623 16.0404L11.0404 14.6262L7.70711 11.2929L6.29289 12.7071ZM11.0404 16.0404L18.3738 8.70711L16.9596 7.29289L9.62623 14.6262L11.0404 16.0404Z"
        style={{
          fill: 'rgb(255, 255, 255)'
        }} /* Note: to fill the transparent check mark with white. WEBAPP-9786 */
      />
    </svg>
  );
};

export default CheckFilled;
