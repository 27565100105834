import React from 'react';

const PlayFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10 15.5C10 15.912 10.4704 16.1472 10.8 15.9L15.4667 12.4C15.7333 12.2 15.7333 11.8 15.4667 11.6L10.8 8.1C10.4704 7.85279 10 8.08798 10 8.5V15.5Z"
      />
    </svg>
  );
};

export default PlayFilledIcon;
