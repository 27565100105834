import React from 'react';

const DeleteIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1C8.34315 1 7 2.34315 7 4V5H5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4V20C4 21.6569 5.34315 23 7 23H17C18.6569 23 20 21.6569 20 20V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19H17V4C17 2.34315 15.6569 1 14 1H10ZM15 5V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V5H15ZM18 7H16H8H6V20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20V7ZM11 10C11 9.44771 10.5523 9 10 9C9.44772 9 9 9.44771 9 10V18C9 18.5523 9.44772 19 10 19C10.5523 19 11 18.5523 11 18V10ZM14 9C14.5523 9 15 9.44771 15 10V18C15 18.5523 14.5523 19 14 19C13.4477 19 13 18.5523 13 18V10C13 9.44771 13.4477 9 14 9Z"
      />
    </svg>
  );
};

export default DeleteIcon;
