import React from 'react';

const PopoverArrowIcon = (props) => {
  return (
    <svg {...props} width="16" height="8" viewBox="0 0 16 8">
      <path d="M10.8284 5.17158C9.26633 6.73367 6.73367 6.73367 5.17157 5.17157L0 0L16 6.99382e-07L10.8284 5.17158Z" />
    </svg>
  );
};

export default PopoverArrowIcon;
