import React from 'react';

const InstagramIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fill="#2C2A38"
        d="M12 2c2.716 0 3.056.012 4.123.06 1.064.049 1.791.218 2.427.465.658.256 1.216.597 1.772 1.153s.898 1.114 1.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123s-.012 3.056-.06 4.123c-.049 1.064-.218 1.791-.465 2.427-.256.658-.598 1.216-1.153 1.772-.556.556-1.114.898-1.772 1.153-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06s-3.056-.012-4.123-.06c-1.064-.049-1.791-.218-2.427-.465-.658-.256-1.216-.598-1.772-1.153-.556-.556-.897-1.114-1.153-1.772-.247-.636-.416-1.363-.465-2.427C2.012 15.056 2 14.716 2 12s.012-3.056.06-4.123c.049-1.064.218-1.791.465-2.427.256-.658.597-1.216 1.153-1.772s1.114-.897 1.772-1.153c.636-.247 1.363-.416 2.427-.465C8.944 2.012 9.284 2 12 2zm0 1.802c-2.67 0-2.986.01-4.04.058-.976.045-1.505.207-1.858.344-.466.182-.8.399-1.15.748-.35.35-.566.684-.748 1.15-.137.353-.3.882-.344 1.857-.048 1.055-.058 1.37-.058 4.041 0 2.67.01 2.986.058 4.04.045.976.207 1.505.344 1.858.182.466.399.8.748 1.15.35.35.684.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058 2.67 0 2.987-.01 4.04-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041 0-2.67-.01-2.986-.058-4.04-.045-.976-.207-1.505-.344-1.858-.182-.466-.398-.8-.748-1.15-.35-.35-.683-.566-1.15-.748-.353-.137-.882-.3-1.857-.344-1.055-.048-1.37-.058-4.041-.058zm0 3.063c2.836 0 5.135 2.299 5.135 5.135S14.836 17.135 12 17.135 6.865 14.836 6.865 12 9.164 6.865 12 6.865zm0 8.468c1.841 0 3.333-1.492 3.333-3.333 0-1.841-1.492-3.333-3.333-3.333-1.841 0-3.333 1.492-3.333 3.333 0 1.841 1.492 3.333 3.333 3.333zm6.538-8.671c0 .663-.537 1.2-1.2 1.2-.663 0-1.2-.537-1.2-1.2 0-.663.537-1.2 1.2-1.2.663 0 1.2.537 1.2 1.2z"
      />
    </svg>
  );
};

export default InstagramIcon;
