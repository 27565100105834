import React from 'react';

function TagsOffIcon(props) {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path d="M17.5514032,14.7229761 L16.1719119,13.3434847 L20.048,9.467 L20.049,6.946 L17.053,3.951 L14.532,3.951 L10.6555153,7.82708813 L9.27590324,6.44747611 L13.723,2 L17.862,2 L21.999,6.137 L21.999,10.275 L17.5514032,14.7229761 Z M14.7230967,17.5515239 L10.275,22 L2,13.724 L6.44759674,9.27602387 L7.82694343,10.6553706 L4.758,13.724 L10.275,19.241 L13.3436294,16.1720566 L14.7230967,17.5515239 Z M3.51471863,2.10050506 L21.8994949,20.4852814 L20.4852814,21.8994949 L2.10050506,3.51471863 L3.51471863,2.10050506 Z M17.2378,9.2381 C16.5548,9.9211 15.4438,9.9211 14.7608,9.2381 C14.0788,8.5551 14.0788,7.4441 14.7608,6.7611 C15.4438,6.0791 16.5548,6.0791 17.2378,6.7611 C17.9208,7.4441 17.9208,8.5551 17.2378,9.2381 Z M15.4898,7.4901 C15.2088,7.7701 15.2088,8.2281 15.4898,8.5101 C15.7698,8.7911 16.2278,8.7911 16.5098,8.5101 C16.7908,8.2281 16.7908,7.7701 16.5098,7.4901 C16.2278,7.2091 15.7698,7.2091 15.4898,7.4901 Z" />
    </svg>
  );
}

export default TagsOffIcon;
