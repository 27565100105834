import React from 'react';

const IntermediateCheckboxFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24  24" {...props}>
      <path d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z" />
      <path d="M7 12H16.3333" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default IntermediateCheckboxFilledIcon;
