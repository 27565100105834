import React from 'react';

const CodeIcon16 = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48532 1.31662C9.86271 1.40049 10.1007 1.77441 10.0168 2.15181L7.35013 14.1518C7.26626 14.5292 6.89234 14.7672 6.51495 14.6833C6.13755 14.5994 5.8996 14.2255 5.98347 13.8481L8.65013 1.8481C8.734 1.47071 9.10792 1.23276 9.48532 1.31662ZM10.8388 4.17173C11.1122 3.89836 11.5554 3.89836 11.8288 4.17173L15.1621 7.50506C15.4355 7.77843 15.4355 8.22164 15.1621 8.49501L11.8288 11.8283C11.5554 12.1017 11.1122 12.1017 10.8388 11.8283C10.5654 11.555 10.5654 11.1118 10.8388 10.8384L13.6772 8.00004L10.8388 5.16168C10.5654 4.88831 10.5654 4.4451 10.8388 4.17173ZM5.1621 5.16168C5.43546 4.88831 5.43546 4.4451 5.1621 4.17173C4.88873 3.89836 4.44551 3.89836 4.17215 4.17173L0.838814 7.50506C0.565447 7.77843 0.565447 8.22164 0.838814 8.49501L4.17215 11.8283C4.44551 12.1017 4.88873 12.1017 5.1621 11.8283C5.43546 11.555 5.43546 11.1118 5.1621 10.8384L2.32374 8.00004L5.1621 5.16168Z"
      />
    </svg>
  );
};

export default CodeIcon16;
