import React from 'react';

const CalendarExportIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3C9 2.44772 8.55228 2 8 2C7.44772 2 7 2.44772 7 3V4H5C3.34315 4 2 5.34315 2 7V19C2 20.6569 3.34315 22 5 22H14C14.5523 22 15 21.5523 15 21C15 20.4477 14.5523 20 14 20H5C4.44772 20 4 19.5523 4 19V7C4 6.44772 4.44772 6 5 6H7V7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7V6H15V7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7V6H19C19.5523 6 20 6.44772 20 7V11C20 11.5523 20.4477 12 21 12C21.5523 12 22 11.5523 22 11V7C22 5.34315 20.6569 4 19 4H17V3C17 2.44772 16.5523 2 16 2C15.4477 2 15 2.44772 15 3V4H9V3ZM6 11C6 10.4477 6.44772 10 7 10H17C17.5523 10 18 10.4477 18 11C18 11.5523 17.5523 12 17 12H7C6.44772 12 6 11.5523 6 11ZM18.7071 20.7071L21.7071 17.7071C22.0976 17.3166 22.0976 16.6834 21.7071 16.2929L18.7071 13.2929C18.3166 12.9024 17.6834 12.9024 17.2929 13.2929C16.9024 13.6834 16.9024 14.3166 17.2929 14.7071L18.5858 16H13C12.4477 16 12 16.4477 12 17C12 17.5523 12.4477 18 13 18H18.5858L17.2929 19.2929C16.9024 19.6834 16.9024 20.3166 17.2929 20.7071C17.6834 21.0976 18.3166 21.0976 18.7071 20.7071Z"
      />
    </svg>
  );
};

export default CalendarExportIcon;
