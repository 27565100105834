import React from 'react';

const LinkIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.021 3.41425C18.4589 1.85215 15.9263 1.85215 14.3642 3.41425L13.0384 4.74007C12.6478 5.1306 12.6478 5.76376 13.0384 6.15429C13.4289 6.54481 14.062 6.54481 14.4526 6.15429L15.7784 4.82846C16.5594 4.04741 17.8258 4.04741 18.6068 4.82846L19.3139 5.53557C20.095 6.31662 20.095 7.58295 19.3139 8.364L14.3642 13.3137C13.5831 14.0948 12.3168 14.0948 11.5358 13.3137L11.1822 12.9602C10.7917 12.5697 10.1585 12.5697 9.76799 12.9602C9.37747 13.3507 9.37747 13.9839 9.76799 14.3744L10.1215 14.728C11.6836 16.2901 14.2163 16.2901 15.7784 14.728L20.7281 9.77821C22.2902 8.21611 22.2902 5.68345 20.7281 4.12136L20.021 3.41425ZM9.77821 10.8285C10.5593 10.0474 11.8256 10.0474 12.6066 10.8285L12.9602 11.182C13.3507 11.5725 13.9839 11.5725 14.3744 11.182C14.7649 10.7915 14.7649 10.1583 14.3744 9.7678L14.0209 9.41425C12.4588 7.85215 9.92609 7.85215 8.364 9.41425L3.41425 14.364C1.85215 15.9261 1.85215 18.4588 3.41425 20.0209L4.12136 20.728C5.68345 22.2901 8.21611 22.2901 9.77821 20.728L11.104 19.4021C11.4946 19.0116 11.4946 18.3784 11.104 17.9879C10.7135 17.5974 10.0803 17.5974 9.68982 17.9879L8.364 19.3137C7.58295 20.0948 6.31662 20.0948 5.53557 19.3137L4.82846 18.6066C4.04741 17.8256 4.04741 16.5593 4.82846 15.7782L9.77821 10.8285Z"
      />
    </svg>
  );
};

export default LinkIcon;
