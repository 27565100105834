import React from 'react';

const EditAddressIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1C3.79893 1 3 2.04689 3 3.11111V19.5C3 20.3428 3.30209 21.1801 3.79249 21.8191C4.2794 22.4535 5.04463 23 6 23H8C8.55228 23 9 22.5523 9 22C9 21.4477 8.55228 21 8 21H6C5.8508 21 5.61603 20.9101 5.37909 20.6014C5.14562 20.2972 5 19.8845 5 19.5C5 19.1155 5.14562 18.7028 5.37909 18.3986C5.61603 18.0899 5.8508 18 6 18H9C9.55228 18 10 17.5523 10 17C10 16.4477 9.55228 16 9 16H6C5.63772 16 5.30279 16.0786 5 16.2107V3.11111C5 3.05439 5.01831 3.01766 5.0316 3H18.9684C18.9817 3.01766 19 3.05439 19 3.11111V8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8V3.11111C21 2.04689 20.2011 1 19 1H5ZM8 12C8 10.8954 8.89543 10 10 10H14C14.5566 10 15.0601 10.2274 15.4226 10.5943C15.7162 10.8914 15.6215 11.3589 15.3272 11.6554L13.2933 13.7045C13.1056 13.8936 12.8501 14 12.5836 14H8.5C8.22386 14 8 13.7761 8 13.5V12ZM12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9ZM13.9382 18.7511L19.4088 13.2694C19.5827 13.0979 19.8198 13 20.0688 13C20.3185 13 20.5563 13.0984 20.7303 13.271C20.904 13.4432 21 13.6749 21 13.9148C21 14.1546 20.904 14.3864 20.7303 14.5586L20.7265 14.5624L15.2505 20.0494L13.4374 20.5581L13.9382 18.7511ZM20.0688 11C19.2941 11 18.5495 11.305 17.9991 11.8507L17.9991 11.8507L17.9954 11.8544L12.3369 17.5243C12.2152 17.6463 12.1271 17.7976 12.0811 17.9636L11.0363 21.7329C10.9399 22.0807 11.0384 22.4534 11.294 22.7082C11.5496 22.963 11.9227 23.0603 12.2701 22.9628L16.0433 21.9042C16.2088 21.8577 16.3595 21.7694 16.481 21.6478L22.1384 15.9789L22.1406 15.9768C22.6899 15.431 23 14.6896 23 13.9148C23 13.139 22.6891 12.3967 22.1384 11.8507C21.5881 11.305 20.8434 11 20.0688 11Z"
      />
    </svg>
  );
};

export default EditAddressIcon;
