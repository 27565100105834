import React from 'react';

const SpaceOffIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2929 0.292893C22.6834 -0.0976311 23.3166 -0.0976311 23.7071 0.292893C24.0976 0.683417 24.0976 1.31658 23.7071 1.70711L21.0908 4.32339C21.6382 4.68023 22 5.29786 22 6V16C22 17.1046 21.1046 18 20 18H17.4142L13.7071 21.7071C13.4211 21.9931 12.991 22.0787 12.6173 21.9239C12.2436 21.7691 12 21.4045 12 21V18H7.41422L1.70711 23.7071C1.31658 24.0976 0.683419 24.0976 0.292893 23.7071C-0.097631 23.3166 -0.097631 22.6834 0.292893 22.2929L22.2929 0.292893ZM9.41422 16H13C13.5523 16 14 16.4477 14 17V18.5858L16.2929 16.2929C16.4804 16.1054 16.7348 16 17 16H20V6H19.4142L9.41422 16ZM4 2C2.89543 2 2 2.89543 2 4V15C2 15.5523 2.44772 16 3 16C3.55229 16 4 15.5523 4 15V4L9.58579 4L11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6H13C13.5523 6 14 5.55228 14 5C14 4.44772 13.5523 4 13 4H12.4142L10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2H4ZM8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12H8.01C8.56229 12 9.01 11.5523 9.01 11C9.01 10.4477 8.56229 10 8.01 10H8ZM15 11C15 10.4477 15.4477 10 16 10H16.01C16.5623 10 17.01 10.4477 17.01 11C17.01 11.5523 16.5623 12 16.01 12H16C15.4477 12 15 11.5523 15 11Z"
      />
    </svg>
  );
};

export default SpaceOffIcon;
