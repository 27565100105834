import React from 'react';

const InboxIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M21 3H3.01001V21H21V3ZM19 15H15C15 16.66 13.65 18 12 18C10.35 18 9.00001 16.66 9.00001 15H5.00001V5H19V15Z" />
    </svg>
  );
};

export default InboxIcon;
