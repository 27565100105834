import React from 'react';

const MarkAsReadIcon = (props) => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" {...props}>
      <path d="M0 0H20V14H15.999L10 18V14H0V0ZM2 2V12H12V14.262L15.394 12H18V2H2ZM12.5849 4L14 5.41506L8.9382 10.4769L6 7.53765L7.41506 6.12159L8.9382 7.64674L12.5849 4Z" />
    </svg>
  );
};

export default MarkAsReadIcon;
