import React from 'react';

const EnvelopeIcon = (props) => {
  return (
    <svg width="24px" height="24px" {...props} viewBox="0 0 24 24">
      <path d="M9.26339 13.1459C9.62013 12.7243 9.56755 12.0934 9.14594 11.7366C8.72434 11.3799 8.09336 11.4325 7.73661 11.8541L9.26339 13.1459ZM10.6621 13.7959L11.331 13.0526L10.6621 13.7959ZM13.3379 13.7959L14.0069 14.5392V14.5392L13.3379 13.7959ZM4 5H20V3H4V5ZM21 6V18H23V6H21ZM20 19H4V21H20V19ZM3 18V6H1V18H3ZM4 19C3.44772 19 3 18.5523 3 18H1C1 19.6569 2.34315 21 4 21V19ZM21 18C21 18.5523 20.5523 19 20 19V21C21.6569 21 23 19.6569 23 18H21ZM20 5C20.5523 5 21 5.44772 21 6H23C23 4.34315 21.6569 3 20 3V5ZM4 3C2.34315 3 1 4.34315 1 6H3C3 5.44772 3.44772 5 4 5V3ZM7.73661 11.8541L2.23661 18.3541L3.76339 19.6459L9.26339 13.1459L7.73661 11.8541ZM14.7366 13.1459L20.2366 19.6459L21.7634 18.3541L16.2634 11.8541L14.7366 13.1459ZM1.33104 6.74329L9.9931 14.5392L11.331 13.0526L2.66896 5.25671L1.33104 6.74329ZM14.0069 14.5392L22.669 6.74329L21.331 5.25671L12.669 13.0526L14.0069 14.5392ZM9.9931 14.5392C11.134 15.566 12.866 15.566 14.0069 14.5392L12.669 13.0526C12.2887 13.3948 11.7113 13.3948 11.331 13.0526L9.9931 14.5392Z" />
    </svg>
  );
};

export default EnvelopeIcon;
