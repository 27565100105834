import React from 'react';

const CodeIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M17 7L22 12L17 17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 21L14 3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 7L2 12L7 17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CodeIcon;
