import React from 'react';

const PinIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7094 2.29317C12.4234 2.00717 11.9933 1.92161 11.6196 2.07639C11.2459 2.23117 11.0023 2.59581 11.0023 3.00027V5.53463C11.0023 5.63589 10.9715 5.73476 10.9141 5.81817L7.13107 11.3129C7.03656 11.4502 6.87987 11.5313 6.71322 11.5293L4.51233 11.5028C4.10571 11.498 3.73662 11.7398 3.57874 12.1145C3.42086 12.4893 3.50564 12.9223 3.79319 13.2099L6.58692 16.0036L3.29289 19.2976C2.90237 19.6882 2.90237 20.3213 3.29289 20.7118C3.68342 21.1024 4.31658 21.1024 4.70711 20.7118L8.00114 17.4178L10.795 20.2117C11.081 20.4977 11.5111 20.5833 11.8848 20.4285C12.2585 20.2737 12.5021 19.9091 12.5021 19.5046L12.5021 17.2898C12.5021 17.1257 12.5826 16.972 12.7175 16.8787L18.1878 13.0915C18.2714 13.0337 18.3707 13.0026 18.4724 13.0026L21.0046 13.0026C21.4091 13.0026 21.7737 12.759 21.9285 12.3853C22.0833 12.0116 21.9977 11.5815 21.7117 11.2955L12.7094 2.29317ZM13.0023 5.53463V5.41449L18.5904 11.0026H18.4724C17.9639 11.0026 17.4675 11.1577 17.0494 11.4472L11.5791 15.2343C10.9575 15.6646 10.567 16.3507 10.5095 17.0978L6.93206 13.5203C7.67272 13.4571 8.35208 13.0663 8.77839 12.4471L12.5614 6.95233C12.8485 6.5353 13.0023 6.04093 13.0023 5.53463Z"
      />
    </svg>
  );
};

export default PinIcon;
