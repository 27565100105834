// @ts-check
import React from 'react';

const EmailIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M5,3 L15,3 L19,7 L19,21 L5,21 L5,3 Z M7,5 L7,19 L17,19 L17,7.836 L14.179,7.836 L14.179,5.008 L14.171,5 L7,5 Z M11,10 L13,10 L13,12 L15,12 L15,14 L13,14 L13,16 L11,16 L11,14 L9,14 L9,12 L11,12 L11,10 Z" />
    </svg>
  );
};

export default EmailIcon;
