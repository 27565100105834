// @ts-check
import React from 'react';

const MinusIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M19 13H5v-2h14v2z" />
    </svg>
  );
};

export default MinusIcon;
