import React from 'react';

const BellIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C12.5523 1 13 1.44772 13 2V3.04828C15.1889 3.26769 16.742 4.22817 17.7419 5.79176C18.8268 7.48839 19.1683 9.76153 19.2395 12.1345C19.2726 13.2385 19.7466 14.4026 20.3269 15.3992C20.9032 16.389 21.5211 17.1068 21.7071 17.2929C21.9931 17.5789 22.0787 18.009 21.9239 18.3827C21.7691 18.7564 21.4045 19 21 19H3.00003C2.59557 19 2.23093 18.7564 2.07615 18.3827C1.92137 18.009 2.00692 17.5789 2.29292 17.2929C2.47898 17.1068 3.09687 16.389 3.67316 15.3992C4.25342 14.4026 4.72746 13.2385 4.76058 12.1345C4.83177 9.76153 5.17328 7.48839 6.2582 5.79176C7.25803 4.22817 8.81114 3.26769 11 3.04828V2C11 1.44772 11.4477 1 12 1ZM12 5C9.83825 5 8.66091 5.74676 7.94316 6.86921C7.16447 8.08696 6.82864 9.89605 6.75968 12.1944C6.71219 13.7775 6.05618 15.2812 5.40155 16.4056C5.28045 16.6135 5.15721 16.8123 5.03476 17H18.9653C18.8428 16.8123 18.7196 16.6135 18.5985 16.4056C17.9439 15.2812 17.2879 13.7775 17.2404 12.1944C17.1714 9.89605 16.8356 8.08696 16.0569 6.86921C15.3391 5.74676 14.1618 5 12 5ZM9.76688 20.1344C10.2449 19.8579 10.8567 20.0212 11.1332 20.4993C11.3076 20.8008 11.6312 21 12 21C12.3689 21 12.6925 20.8008 12.8669 20.4993C13.1434 20.0212 13.7551 19.8579 14.2332 20.1344C14.7113 20.4109 14.8746 21.0227 14.5981 21.5007C14.0809 22.3948 13.1117 23 12 23C10.8884 23 9.91921 22.3948 9.402 21.5007C9.12546 21.0227 9.28882 20.4109 9.76688 20.1344Z"
      />
    </svg>
  );
};

export default BellIcon;
