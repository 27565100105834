import React from 'react';

const MenuIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M3,13.001 L3,11 L21,11 L21,13.001 L3,13.001 Z M3,18 L3,16 L21,16 L21,18 L3,18 Z M3,8 L3,6 L21,6 L21,8 L3,8 Z" />
    </svg>
  );
};

export default MenuIcon;
