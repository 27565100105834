import React from 'react';

const DocumentSparcleIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H14C14.5523 22 15 21.5523 15 21C15 20.4477 14.5523 20 14 20H6V4L12 4V9C12 9.55228 12.4477 10 13 10H18V13C18 13.5523 18.4477 14 19 14C19.5523 14 20 13.5523 20 13V8.80599C20 8.28531 19.7969 7.78515 19.434 7.41183L14.7615 2.60584C14.3849 2.21852 13.8677 2 13.3275 2H6ZM17.2164 8L14 4.6917V8H17.2164ZM13.3035 13.607L12.1789 11.3578C12.1052 11.2104 11.8948 11.2104 11.8211 11.3578L10.6965 13.607C10.6771 13.6457 10.6457 13.6771 10.607 13.6965L8.35777 14.8211C8.21036 14.8948 8.21036 15.1052 8.35777 15.1789L10.607 16.3035C10.6457 16.3229 10.6771 16.3543 10.6965 16.393L11.8211 18.6422C11.8948 18.7896 12.1052 18.7896 12.1789 18.6422L13.3035 16.393C13.3229 16.3543 13.3543 16.3229 13.393 16.3035L15.6422 15.1789C15.7896 15.1052 15.7896 14.8948 15.6422 14.8211L13.393 13.6965C13.3543 13.6771 13.3229 13.6457 13.3035 13.607ZM19.1789 16.3578L19.9702 17.9404C19.9895 17.9791 20.0209 18.0105 20.0596 18.0298L21.6422 18.8211C21.7896 18.8948 21.7896 19.1052 21.6422 19.1789L20.0596 19.9702C20.0209 19.9895 19.9895 20.0209 19.9702 20.0596L19.1789 21.6422C19.1052 21.7896 18.8948 21.7896 18.8211 21.6422L18.0298 20.0596C18.0105 20.0209 17.9791 19.9895 17.9404 19.9702L16.3578 19.1789C16.2104 19.1052 16.2104 18.8948 16.3578 18.8211L17.9404 18.0298C17.9791 18.0105 18.0105 17.9791 18.0298 17.9404L18.8211 16.3578C18.8948 16.2104 19.1052 16.2104 19.1789 16.3578Z"
      />
    </svg>
  );
};

export default DocumentSparcleIcon;
