import React from 'react';

const ClockIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12,2 C17.522,2 22,6.477 22,12 C22,17.523 17.522,22 12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 Z M12,4 C7.589,4 4,7.589 4,12 C4,16.411 7.589,20 12,20 C16.411,20 20,16.411 20,12 C20,7.589 16.411,4 12,4 Z M17,13 L11,13 L11,7 L12.5,7 L12.5,11.5 L17,11.5 L17,13 Z" />
    </svg>
  );
};

export default ClockIcon;
