import React from 'react';

const ArrowDropDownIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="7 9.5 12 14.5 17 9.5" />
    </svg>
  );
};

export default ArrowDropDownIcon;
