import React from 'react';

const FileeeSignetIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M0 0v24h24V0H0zm14.829 3.158l1.829 1.28-5.263.929-.41-1.531 3.844-.678zm-2.611 17.684l-5.054-7.147 1.773-.234 4.777 6.757-1.496.624zm-1.345-7.637l1.569-.207 1.219 6.916-2.185-3.288-.603-3.42zm-3.805.354l.715-1.727 2.761-.49-.996-5.648 1.283-1.832 1.27 7.204 2.76-.486.273 1.555-8.066 1.424zm8.306-7.29l-.224-1.265 1.535-.41.248 1.4-1.56.275z" />
    </svg>
  );
};

export default FileeeSignetIcon;
