import React from 'react';

const GridViewLargeIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2C2.44772 2 2 2.44772 2 3V10C2 10.5523 2.44772 11 3 11H21C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H3ZM4 9V4H20V9H4ZM3 13C2.44772 13 2 13.4477 2 14V21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21V14C22 13.4477 21.5523 13 21 13H3ZM4 20V15H20V20H4Z"
      />
    </svg>
  );
};

export default GridViewLargeIcon;
