import React from 'react';

const OverflowHorizontalIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M10,14 L10,10 L14,10 L14,14 L10,14 Z M4,14 L4,10 L8,10 L8,14 L4,14 Z M16,14 L16,10 L20.001,10 L20.001,14 L16,14 Z" />
    </svg>
  );
};

export default OverflowHorizontalIcon;
