// @ts-check
import React from 'react';

const AddIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="11 4 11 11 4 11 4 13 11 13 11 20 13 20 13 13 20 13 20 11 13 11 13 4" />
    </svg>
  );
};

export default AddIcon;
