import React from 'react';

const CheckCircleNewIcon = (props) => {
  return (
    <svg width="24px" height="24px" {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 7.58172 7.58172 4 12 4C13.1396 4 14.2211 4.23768 15.1996 4.66523C15.7057 4.88635 16.2952 4.65534 16.5164 4.14925C16.7375 3.64316 16.5065 3.05364 16.0004 2.83253C14.7741 2.29673 13.4204 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6632 21.9833 11.33 21.9507 11.0012C21.8961 10.4516 21.4063 10.0503 20.8568 10.1049C20.3072 10.1595 19.9059 10.6492 19.9605 11.1988C19.9866 11.462 20 11.7293 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM23.1863 4.72729C23.588 4.34824 23.6063 3.71534 23.2273 3.31367C22.8482 2.912 22.2153 2.89366 21.8137 3.27271L12.0202 12.5147L9.70711 10.2016C9.31658 9.81106 8.68342 9.81106 8.2929 10.2016C7.90237 10.5921 7.90237 11.2253 8.2929 11.6158L11.2929 14.6158C11.6754 14.9983 12.2929 15.0073 12.6863 14.636L23.1863 4.72729Z"
      />
    </svg>
  );
};

export default CheckCircleNewIcon;
