import React from 'react';

const PlayIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10 15.5C10 15.912 10.4704 16.1472 10.8 15.9L15.4667 12.4C15.7333 12.2 15.7333 11.8 15.4667 11.6L10.8 8.1C10.4704 7.85279 10 8.08798 10 8.5V15.5Z"
      />
    </svg>
  );
};

export default PlayIcon;
