import React from 'react';

const BookmarkFilledIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M7 3H17C18.1 3 19 3.9 19 5V21L12 18L5 21L5.01 5C5.01 3.9 5.9 3 7 3ZM12 7C12.1723 7 12.3291 7.0996 12.4023 7.25559L13.4003 9.38161L15.6231 9.72323C15.7877 9.74853 15.9243 9.86373 15.9771 10.0217C16.0299 10.1796 15.9899 10.3539 15.8736 10.473L14.2526 12.1332L14.636 14.484C14.6634 14.652 14.5924 14.8209 14.4533 14.9189C14.3141 15.0169 14.1312 15.0269 13.9823 14.9445L12 13.8483L10.0178 14.9445C9.8688 15.0269 9.68589 15.0169 9.54673 14.9189C9.40758 14.8209 9.33663 14.652 9.36403 14.484L9.74746 12.1332L8.12645 10.473C8.01011 10.3539 7.97014 10.1796 8.02291 10.0217C8.07568 9.86373 8.21233 9.74853 8.37694 9.72323L10.5997 9.38161L11.5977 7.25559C11.6709 7.0996 11.8277 7 12 7Z"
      />
    </svg>
  );
};

export default BookmarkFilledIcon;
