// @ts-check
import React from 'react';

const KeyboardArrowUpIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="12 7 4 14.999 5.419 16.419 12 9.838 18.581 16.419 20 14.999" />
    </svg>
  );
};

export default KeyboardArrowUpIcon;
