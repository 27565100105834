// @ts-check
import React from 'react';

const TemplateIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M5,3 L15,3 L19,7 L19,21 L5,21 L5,3 Z M7,5 L7,19 L17,19 L17,7.836 L14.179,7.836 L14.179,5.008 L14.171,5 L7,5 Z M9,11 L15,11 L15,13 L9,13 L9,11 Z M9,15 L15,15 L15,17 L9,17 L9,15 Z" />
    </svg>
  );
};

export default TemplateIcon;
