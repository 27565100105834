import React from 'react';

const AndroidLogo = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M6,18 L6,8 L18,8 L18,18 C18,18.55 17.55,19 17,19 L16,19 L16,22.5 C16,23.33 15.33,24 14.5,24 C13.67,24 13,23.33 13,22.5 L13,19 L11,19 L11,22.5 C11,23.33 10.33,24 9.5,24 C8.67,24 8,23.33 8,22.5 L8,19 L7,19 C6.45,19 6,18.55 6,18 Z M3.5,8 C4.33,8 5,8.67 5,9.5 L5,16.5 C5,17.33 4.33,18 3.5,18 C2.67,18 2,17.33 2,16.5 L2,9.5 C2,8.67 2.67,8 3.5,8 Z M20.5,8 C21.33,8 22,8.67 22,9.5 L22,16.5 C22,17.33 21.33,18 20.5,18 C19.67,18 19,17.33 19,16.5 L19,9.5 C19,8.67 19.67,8 20.5,8 Z M15.53,2.16 C17.03,3.25 18,5.01 18,7 L6,7 C6,5.01 6.97,3.26 8.45,2.17 L7.14,0.86 C6.94,0.66 6.94,0.35 7.14,0.15 C7.34,-0.05 7.65,-0.05 7.85,0.15 L9.34,1.63 C10.14,1.23 11.04,1 12,1 C12.95,1 13.85,1.23 14.64,1.63 L16.12,0.15 C16.32,-0.05 16.63,-0.05 16.83,0.15 C17.03,0.35 17.03,0.66 16.83,0.86 L15.53,2.16 Z M10,5 L10,4 L9,4 L9,5 L10,5 Z M15,5 L15,4 L14,4 L14,5 L15,5 Z" />
    </svg>
  );
};

export default AndroidLogo;
