// @ts-check
import React from 'react';

const ContactsIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3.11111C3 2.04689 3.79893 1 5 1H19C20.2011 1 21 2.04689 21 3.11111V17V20.8889C21 21.9531 20.2011 23 19 23H6C5.04463 23 4.2794 22.4535 3.79249 21.8191C3.30209 21.1801 3 20.3428 3 19.5V3.11111ZM5 19.5C5 19.8845 5.14562 20.2972 5.37909 20.6014C5.61603 20.9101 5.8508 21 6 21H18.9684C18.9817 20.9823 19 20.9456 19 20.8889V18H6C5.8508 18 5.61603 18.0899 5.37909 18.3986C5.14562 18.7028 5 19.1155 5 19.5ZM19 16H6C5.63772 16 5.30279 16.0786 5 16.2107V3.11111C5 3.05439 5.01831 3.01766 5.0316 3H18.9684C18.9817 3.01766 19 3.05439 19 3.11111V16ZM8 12C8 10.8954 8.89543 10 10 10H14C15.1046 10 16 10.8954 16 12V13.5C16 13.7761 15.7761 14 15.5 14H8.5C8.22386 14 8 13.7761 8 13.5V12ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
      />
    </svg>
  );
};

export default ContactsIcon;
