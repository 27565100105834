import React from 'react';

const MoveIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path d="M11.6598 2.05936C11.5258 2.1077 11.4001 2.18554 11.2928 2.29289L8.59279 4.99289C8.20227 5.38342 8.20227 6.01658 8.59279 6.40711C8.98331 6.79763 9.61648 6.79763 10.007 6.40711L11 5.41411V11H5.41426L6.40711 10.0072C6.79763 9.61663 6.79763 8.98347 6.40711 8.59294C6.01658 8.20242 5.38342 8.20242 4.99289 8.59294L2.29882 11.287C2.27766 11.3078 2.25743 11.3296 2.23818 11.3522C2.17452 11.4269 2.12362 11.5087 2.08548 11.5948C2.03053 11.7187 2 11.8558 2 12L2.00004 12.0093C2.00117 12.1336 2.025 12.2526 2.06759 12.3621C2.11633 12.4879 2.19143 12.6057 2.29289 12.7072L4.99289 15.4072C5.38342 15.7977 6.01658 15.7977 6.40711 15.4072C6.79763 15.0166 6.79763 14.3835 6.40711 13.9929L5.41417 13H11V18.586L10.0069 17.5929C9.61639 17.2024 8.98322 17.2024 8.5927 17.5929C8.20217 17.9835 8.20217 18.6166 8.5927 19.0072L11.2927 21.7072C11.4224 21.8368 11.5788 21.9234 11.7442 21.967C11.7851 21.9778 11.8265 21.9859 11.8682 21.9914C11.9386 22.0007 12.0098 22.0025 12.0805 21.9968C12.2685 21.9818 12.4418 21.9149 12.5861 21.8103C12.6331 21.7763 12.677 21.7383 12.7173 21.6968L15.4069 19.0072C15.7974 18.6166 15.7974 17.9835 15.4069 17.5929C15.0164 17.2024 14.3832 17.2024 13.9927 17.5929L13 18.5856V13H18.5856L17.5927 13.9929C17.2022 14.3835 17.2022 15.0166 17.5927 15.4072C17.9832 15.7977 18.6164 15.7977 19.0069 15.4072L21.6968 12.7173C21.8838 12.5356 22 12.2813 22 12C22 11.7171 21.8826 11.4617 21.6938 11.2798L19.0069 8.59294C18.6164 8.20242 17.9832 8.20242 17.5927 8.59294C17.2022 8.98347 17.2022 9.61663 17.5927 10.0072L18.5855 11H13V5.41432L13.9928 6.40711C14.3833 6.79763 15.0165 6.79763 15.407 6.40711C15.7975 6.01658 15.7975 5.38342 15.407 4.99289L12.7157 2.30156C12.6819 2.26697 12.6457 2.23483 12.6072 2.2054C12.5538 2.16445 12.4971 2.12961 12.4383 2.10091C12.306 2.03629 12.1573 2.00002 12.0001 2M11.6598 2.05936C11.7646 2.02155 11.8744 2.0018 11.9845 2.00012C11.9897 2.00004 11.9948 2 12 2" />
    </svg>
  );
};

export default MoveIcon;
