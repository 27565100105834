// @ts-check
import React from 'react';

const KeyboardArrowLeftIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="15 4 7 12 15 20 16.418 18.581 9.838 12 16.418 5.419" />
    </svg>
  );
};

export default KeyboardArrowLeftIcon;
