import React from 'react';

const WarningRhombusFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2491 1.93159C13.0069 0.689472 10.9931 0.689468 9.75094 1.93159L1.93159 9.75094C0.689472 10.9931 0.689468 13.0069 1.93159 14.2491L9.75094 22.0684C10.9931 23.3105 13.0069 23.3105 14.2491 22.0684L22.0684 14.2491C23.3105 13.0069 23.3105 10.9931 22.0684 9.75094L14.2491 1.93159ZM12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V8C11 7.44772 11.4477 7 12 7ZM12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H12.01C12.5623 17 13.01 16.5523 13.01 16C13.01 15.4477 12.5623 15 12.01 15H12Z"
      />
    </svg>
  );
};

export default WarningRhombusFilledIcon;
