import React from 'react';

const VariableIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24  24" {...props}>
      <path d="M5.43945 11.2171C5.76657 11.2171 6.04598 11.162 6.27768 11.0518C6.50939 10.9279 6.7002 10.7764 6.85013 10.5973C7.01369 10.4045 7.12954 10.191 7.19769 9.9568C7.27947 9.70887 7.32036 9.46094 7.32036 9.213V5.494C7.32036 4.92926 7.38169 4.4265 7.50436 3.98573C7.62702 3.54496 7.83829 3.17995 8.13814 2.89069C8.43799 2.58766 8.83326 2.36039 9.32393 2.20887C9.82823 2.04358 10.4484 1.96094 11.1844 1.96094H11.2662V3.4692C10.4893 3.4692 9.93045 3.61383 9.58971 3.90309C9.26259 4.17857 9.09904 4.71576 9.09904 5.51466V8.69648C9.09904 9.60557 8.97637 10.308 8.73103 10.8039C8.49933 11.2998 8.1654 11.6855 7.72925 11.9609C8.1654 12.2364 8.49933 12.6221 8.73103 13.118C8.97637 13.6138 9.09904 14.3163 9.09904 15.2254V18.4072C9.09904 19.2061 9.26259 19.7433 9.58971 20.0188C9.93045 20.308 10.4893 20.4527 11.2662 20.4527V21.9609H11.1844C10.4484 21.9609 9.82823 21.8852 9.32393 21.7337C8.83326 21.5821 8.43799 21.3549 8.13814 21.0518C7.83829 20.7488 7.62702 20.3769 7.50436 19.9361C7.38169 19.4954 7.32036 18.9926 7.32036 18.4279V14.7089C7.32036 14.4609 7.27947 14.2199 7.19769 13.9857C7.12954 13.7378 7.01369 13.5243 6.85013 13.3452C6.7002 13.1524 6.50939 13.0009 6.27768 12.8907C6.04598 12.7667 5.76657 12.7047 5.43945 12.7047V11.2171Z" />
      <path d="M12.6127 11.2171C12.9399 11.2171 13.2193 11.162 13.451 11.0518C13.6827 10.9279 13.8735 10.7764 14.0234 10.5973C14.187 10.4045 14.3028 10.191 14.371 9.9568C14.4528 9.70887 14.4936 9.46094 14.4936 9.213V5.494C14.4936 4.92926 14.555 4.4265 14.6776 3.98573C14.8003 3.54496 15.0116 3.17995 15.3114 2.89069C15.6113 2.58766 16.0065 2.36039 16.4972 2.20887C17.0015 2.04358 17.6217 1.96094 18.3577 1.96094H18.4395V3.4692C17.6626 3.4692 17.1037 3.61383 16.763 3.90309C16.4359 4.17857 16.2723 4.71576 16.2723 5.51466V8.69648C16.2723 9.60557 16.1497 10.308 15.9043 10.8039C15.6726 11.2998 15.3387 11.6855 14.9025 11.9609C15.3387 12.2364 15.6726 12.6221 15.9043 13.118C16.1497 13.6138 16.2723 14.3163 16.2723 15.2254V18.4072C16.2723 19.2061 16.4359 19.7433 16.763 20.0188C17.1037 20.308 17.6626 20.4527 18.4395 20.4527V21.9609H18.3577C17.6217 21.9609 17.0015 21.8852 16.4972 21.7337C16.0065 21.5821 15.6113 21.3549 15.3114 21.0518C15.0116 20.7488 14.8003 20.3769 14.6776 19.9361C14.555 19.4954 14.4936 18.9926 14.4936 18.4279V14.7089C14.4936 14.4609 14.4528 14.2199 14.371 13.9857C14.3028 13.7378 14.187 13.5243 14.0234 13.3452C13.8735 13.1524 13.6827 13.0009 13.451 12.8907C13.2193 12.7667 12.9399 12.7047 12.6127 12.7047V11.2171Z" />
    </svg>
  );
};

export default VariableIcon;
