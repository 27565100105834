import React from 'react';

const DocumentEditOutlineIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89543 2.89543 2 4 2H11.3275C11.8677 2 12.3849 2.21852 12.7615 2.60584L17.434 7.41183C17.7969 7.78515 18 8.28531 18 8.80599V9C18 9.55228 17.5523 10 17 10H11C10.4477 10 10 9.55228 10 9V4L4 4V20H8C8.55228 20 9 20.4477 9 21C9 21.5523 8.55228 22 8 22H4C2.89543 22 2 21.1046 2 20V4ZM12 4.6917L15.2164 8H12V4.6917ZM6 13C6 12.4477 6.44772 12 7 12L13 12C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14L7 14C6.44772 14 6 13.5523 6 13ZM7 18L10 18C10.5523 18 11 17.5523 11 17C11 16.4477 10.5523 16 10 16L7 16C6.44771 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18ZM13.9382 18.7511L19.4088 13.2694C19.5827 13.0979 19.8198 13 20.0688 13C20.3185 13 20.5563 13.0984 20.7303 13.271C20.904 13.4432 21 13.6749 21 13.9148C21 14.1546 20.904 14.3864 20.7303 14.5586L20.7265 14.5624L15.2505 20.0494L13.4374 20.5581L13.9382 18.7511ZM20.0688 11C19.2941 11 18.5495 11.305 17.9991 11.8507L17.9991 11.8507L17.9954 11.8544L12.3369 17.5243C12.2152 17.6463 12.1271 17.7976 12.0811 17.9636L11.0363 21.7329C10.9399 22.0807 11.0384 22.4534 11.294 22.7082C11.5496 22.963 11.9227 23.0603 12.2701 22.9628L16.0433 21.9042C16.2088 21.8577 16.3595 21.7694 16.481 21.6478L22.1384 15.9789L22.1406 15.9768C22.6899 15.431 23 14.6896 23 13.9148C23 13.139 22.6891 12.3967 22.1384 11.8507C21.5881 11.305 20.8434 11 20.0688 11Z"
      />
    </svg>
  );
};

export default DocumentEditOutlineIcon;
