import React from 'react';

const BookmarkOutlineIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path d="M 12 7 C 12.1723 7 12.3291 7.0996 12.4023 7.25559 L 13.4003 9.38161 L 15.6231 9.72323 C 15.7877 9.74853 15.9243 9.86373 15.9771 10.0217 C 16.0299 10.1796 15.9899 10.3539 15.8736 10.473 L 14.2526 12.1332 L 14.636 14.484 C 14.6634 14.652 14.5924 14.8209 14.4533 14.9189 C 14.3141 15.0169 14.1312 15.0269 13.9823 14.9445 L 12 13.8483 L 10.0178 14.9445 C 9.8688 15.0269 9.68589 15.0169 9.54673 14.9189 C 9.40758 14.8209 9.33663 14.652 9.36403 14.484 L 9.74746 12.1332 L 8.12645 10.473 C 8.01011 10.3539 7.97014 10.1796 8.02291 10.0217 C 8.07568 9.86373 8.21233 9.74853 8.37694 9.72323 L 10.5997 9.38161 L 11.5977 7.25559 C 11.6709 7.0996 11.8277 7 12 7 Z" />
      <path d="M 7 3 L 17 3 C 18.1 3 19 3.9 19 5 L 19 21 L 12 18 L 5 21 L 5.01 5 C 5.01 3.9 5.9 3 7 3 Z M 12 15.82 L 17 18 L 17 5 L 7 5 L 7 18 Z" />
    </svg>
  );
};

export default BookmarkOutlineIcon;
