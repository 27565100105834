import React from 'react';

const AppleMediumLogo = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M18.0339 12.7455C18.0629 16.0895 20.9666 17.2023 21 17.2176C20.9743 17.2949 20.535 18.8031 19.4693 20.3612C18.5471 21.7078 17.5925 23.0485 16.0865 23.077C14.6051 23.1044 14.1291 22.1985 12.4369 22.1985C10.7447 22.1985 10.2151 23.0485 8.81528 23.1044C7.3618 23.1586 6.255 21.6503 5.32618 20.3075C3.42637 17.5638 1.97619 12.5522 3.92528 9.16934C4.89134 7.48914 6.62193 6.4256 8.49818 6.39821C9.92591 6.37192 11.2737 7.35989 12.1456 7.35989C13.0191 7.35989 14.6565 6.17203 16.3778 6.34619C17.0985 6.37631 19.1216 6.63644 20.42 8.5368C20.3165 8.60361 18.006 9.94645 18.0339 12.7455ZM15.2502 4.53017C14.478 5.46392 13.2113 6.1912 11.9708 6.09427C11.8033 4.82481 12.4292 3.50277 13.145 2.67417C13.9419 1.74043 15.2891 1.04546 16.4008 1C16.5427 2.2952 16.0218 3.59423 15.2502 4.53017Z" />
    </svg>
  );
};

export default AppleMediumLogo;
