import React from 'react';

const CloneOutlineIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1C8.34315 1 7 2.34315 7 4V14C7 15.6569 8.34315 17 10 17H20C21.6569 17 23 15.6569 23 14V4C23 2.34315 21.6569 1 20 1H10ZM9 4C9 3.44772 9.44772 3 10 3H20C20.5523 3 21 3.44772 21 4V14C21 14.5523 20.5523 15 20 15H10C9.44772 15 9 14.5523 9 14V4ZM4 7C2.34315 7 1 8.34315 1 10V20C1 21.6569 2.34315 23 4 23H14C15.6569 23 17 21.6569 17 20C17 19.4477 16.5523 19 16 19C15.4477 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21H4C3.44772 21 3 20.5523 3 20V10C3 9.44772 3.44772 9 4 9C4.55228 9 5 8.55228 5 8C5 7.44772 4.55228 7 4 7ZM15 5C15.5523 5 16 5.44772 16 6V8H18C18.5523 8 19 8.44772 19 9C19 9.55229 18.5523 10 18 10H16V12C16 12.5523 15.5523 13 15 13C14.4477 13 14 12.5523 14 12V10H12C11.4477 10 11 9.55228 11 9C11 8.44772 11.4477 8 12 8H14V6C14 5.44772 14.4477 5 15 5Z"
      />
    </svg>
  );
};

export default CloneOutlineIcon;
