// @ts-check
import React from 'react';

const UserPlusIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C6 5.34315 7.34315 4 9 4C10.6569 4 12 5.34315 12 7C12 8.65685 10.6569 10 9 10C7.34315 10 6 8.65685 6 7ZM9 2C6.23858 2 4 4.23858 4 7C4 9.76142 6.23858 12 9 12C11.7614 12 14 9.76142 14 7C14 4.23858 11.7614 2 9 2ZM5 14C3.67392 14 2.40215 14.5268 1.46447 15.4645C0.526784 16.4021 0 17.6739 0 19V21C0 21.5523 0.447715 22 1 22C1.55228 22 2 21.5523 2 21V19C2 18.2043 2.31607 17.4413 2.87868 16.8787C3.44129 16.3161 4.20435 16 5 16H13C13.7956 16 14.5587 16.3161 15.1213 16.8787C15.6839 17.4413 16 18.2044 16 19V21C16 21.5523 16.4477 22 17 22C17.5523 22 18 21.5523 18 21V19C18 17.6739 17.4732 16.4021 16.5355 15.4645C15.5979 14.5268 14.3261 14 13 14H5ZM20 7C20.5523 7 21 7.44772 21 8V10H23C23.5523 10 24 10.4477 24 11C24 11.5523 23.5523 12 23 12H21V14C21 14.5523 20.5523 15 20 15C19.4477 15 19 14.5523 19 14V12H17C16.4477 12 16 11.5523 16 11C16 10.4477 16.4477 10 17 10H19V8C19 7.44772 19.4477 7 20 7Z"
      />
    </svg>
  );
};

export default UserPlusIcon;
