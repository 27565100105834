import React from 'react';

const SliderIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1C16.5523 1 17 1.44772 17 2V3L21 3C21.5523 3 22 3.44772 22 4C22 4.55228 21.5523 5 21 5H17V6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6V4V2C15 1.44772 15.4477 1 16 1ZM21 19H15V18C15 17.4477 14.5523 17 14 17C13.4477 17 13 17.4477 13 18V20V22C13 22.5523 13.4477 23 14 23C14.5523 23 15 22.5523 15 22V21H21C21.5523 21 22 20.5523 22 20C22 19.4477 21.5523 19 21 19ZM21 11L12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13L21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11ZM8 9C8.55228 9 9 9.44771 9 10V12V14C9 14.5523 8.55229 15 8 15C7.44772 15 7 14.5523 7 14V13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H7V10C7 9.44772 7.44772 9 8 9ZM12 3L3 3C2.44772 3 2 3.44772 2 4C2 4.55229 2.44772 5 3 5L12 5C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3ZM10 19H3C2.44772 19 2 19.4477 2 20C2 20.5523 2.44772 21 3 21H10C10.5523 21 11 20.5523 11 20C11 19.4477 10.5523 19 10 19Z"
      />
    </svg>
  );
};

export default SliderIcon;
