import React from 'react';

const AppleLogo = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M 11.53125 7.785156 C 12.136719 7.785156 12.898438 7.363281 13.355469 6.800781 C 13.765625 6.292969 14.0625 5.582031 14.0625 4.871094 C 14.0625 4.777344 14.054688 4.679688 14.039062 4.601562 C 13.363281 4.625 12.550781 5.066406 12.0625 5.652344 C 11.675781 6.101562 11.324219 6.800781 11.324219 7.519531 C 11.324219 7.625 11.34375 7.730469 11.351562 7.765625 C 11.394531 7.777344 11.460938 7.785156 11.53125 7.785156 Z M 9.390625 18.398438 C 10.222656 18.398438 10.589844 17.828125 11.625 17.828125 C 12.675781 17.828125 12.910156 18.382812 13.832031 18.382812 C 14.742188 18.382812 15.347656 17.523438 15.921875 16.679688 C 16.5625 15.714844 16.828125 14.769531 16.847656 14.722656 C 16.785156 14.707031 15.046875 13.976562 15.046875 11.933594 C 15.046875 10.160156 16.417969 9.363281 16.496094 9.300781 C 15.585938 7.96875 14.210938 7.933594 13.832031 7.933594 C 12.816406 7.933594 11.984375 8.566406 11.460938 8.566406 C 10.898438 8.566406 10.152344 7.96875 9.269531 7.96875 C 7.59375 7.96875 5.890625 9.390625 5.890625 12.074219 C 5.890625 13.742188 6.523438 15.503906 7.300781 16.644531 C 7.96875 17.609375 8.550781 18.398438 9.390625 18.398438 Z M 9.390625 18.398438 " />
    </svg>
  );
};

export default AppleLogo;
