// @ts-check
import React from 'react';

const ArrowLeftIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="11.974 4 4 11.972 11.973 19.946 13.387 18.532 7.828 12.973 19.995 12.973 19.995 10.972 7.828 10.972 13.388 5.414" />
    </svg>
  );
};

export default ArrowLeftIcon;
