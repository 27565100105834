import React from 'react';

const DocumentExportIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C4 2.89543 4.89543 2 6 2H13.3275C13.8677 2 14.3849 2.21852 14.7615 2.60584L19.434 7.41183C19.7969 7.78515 20 8.28531 20 8.80599V10C20 10.5523 19.5523 11 19 11C18.4477 11 18 10.5523 18 10H13C12.4477 10 12 9.55231 12 9.00003V4L6 4V20H14C14.5523 20 15 20.4477 15 21C15 21.5523 14.5523 22 14 22H6C4.89543 22 4 21.1046 4 20V4ZM14 4.6917L17.2164 8.00003H14V4.6917ZM18.7071 20.7071L21.7071 17.7071C22.0976 17.3166 22.0976 16.6834 21.7071 16.2929L18.7071 13.2929C18.3166 12.9024 17.6834 12.9024 17.2929 13.2929C16.9024 13.6834 16.9024 14.3166 17.2929 14.7071L18.5858 16H13C12.4477 16 12 16.4477 12 17C12 17.5523 12.4477 18 13 18H18.5858L17.2929 19.2929C16.9024 19.6834 16.9024 20.3166 17.2929 20.7071C17.6834 21.0976 18.3166 21.0976 18.7071 20.7071Z"
      />
    </svg>
  );
};

export default DocumentExportIcon;
