import React from 'react';

const PencilIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15.232 5.22783L18.768 8.75671M16.732 3.73085C17.2009 3.2629 17.8369 3 18.5 3C19.1631 3 19.7991 3.2629 20.268 3.73085C20.7369 4.19881 21.0003 4.8335 21.0003 5.49529C21.0003 6.15709 20.7369 6.79177 20.268 7.25973L8.46884 19.034L3 21L4.93644 15.5016L16.732 3.73085Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilIcon;
