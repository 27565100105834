import React from 'react';

const DoubleChevronTopIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M7 18L12 13L17 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 11L12 6L17 11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DoubleChevronTopIcon;
