import React from 'react';

const TimelineIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M5 3C3.89543 3 3 3.89543 3 5C3 5.74028 3.4022 6.38663 4 6.73244V10.2676C3.4022 10.6134 3 11.2597 3 12C3 12.7403 3.4022 13.3866 4 13.7324V17.2676C3.4022 17.6134 3 18.2597 3 19C3 20.1046 3.89543 21 5 21C6.10457 21 7 20.1046 7 19C7 18.2597 6.5978 17.6134 6 17.2676V13.7324C6.5978 13.3866 7 12.7403 7 12C7 11.2597 6.5978 10.6134 6 10.2676V6.73244C6.5978 6.38663 7 5.74028 7 5C7 3.89543 6.10457 3 5 3ZM10 3C9.44772 3 9 3.44772 9 4V6C9 6.55228 9.44772 7 10 7H20C20.5523 7 21 6.55228 21 6V4C21 3.44772 20.5523 3 20 3H10ZM9 11C9 10.4477 9.44772 10 10 10H20C20.5523 10 21 10.4477 21 11V13C21 13.5523 20.5523 14 20 14H10C9.44772 14 9 13.5523 9 13V11ZM10 17C9.44772 17 9 17.4477 9 18V20C9 20.5523 9.44772 21 10 21H20C20.5523 21 21 20.5523 21 20V18C21 17.4477 20.5523 17 20 17H10Z" />
    </svg>
  );
};

export default TimelineIcon;
