import React from 'react';

const ArrowDropDownRightIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon id="ic_arrow_drop_right-a" points="9.5 17 14.5 12 9.5 7" />
    </svg>
  );
};

export default ArrowDropDownRightIcon;
