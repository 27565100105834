// @ts-check
import React from 'react';

const ClearIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24  24" {...props}>
      <polygon points="18.581 4 12 10.581 5.419 4 4 5.419 10.581 12 4 18.58 5.419 19.999 12 13.419 18.581 20 20 18.581 13.419 12 20 5.419" />
    </svg>
  );
};

export default ClearIcon;
