import React from 'react';

const FilterIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M6,13.184 C7.163,13.597 8,14.696 8,16 C8,17.305 7.163,18.403 6,18.816 L6,21 L4,21 L4,18.816 C2.837,18.403 2,17.305 2,16 C2,14.696 2.837,13.597 4,13.184 L4,3 L6,3 L6,13.184 Z M6,16.623 C6.116,16.439 6.2,16.233 6.2,16 C6.2,15.767 6.116,15.561 6,15.377 C5.788,15.039 5.428,14.8 5,14.8 C4.571,14.8 4.212,15.039 4,15.377 C3.884,15.561 3.8,15.767 3.8,16 C3.8,16.233 3.884,16.439 4,16.623 C4.212,16.962 4.571,17.2 5,17.2 C5.428,17.2 5.788,16.962 6,16.623 Z M13,6.1664 C14.163,6.5794 15,7.6784 15,8.9824 C15,10.2864 14.163,11.3854 13,11.7984 L13,21.0004 L11,21.0004 L11,11.7984 C9.837,11.3854 9,10.2864 9,8.9824 C9,7.6784 9.837,6.5794 11,6.1664 L11,3.0004 L13,3.0004 L13,6.1664 Z M13,9.6054 C13.116,9.4204 13.2,9.2154 13.2,8.9824 C13.2,8.7494 13.116,8.5434 13,8.3594 C12.788,8.0204 12.428,7.7824 12,7.7824 C11.571,7.7824 11.212,8.0204 11,8.3594 C10.884,8.5434 10.8,8.7494 10.8,8.9824 C10.8,9.2154 10.884,9.4214 11,9.6054 C11.212,9.9444 11.571,10.1824 12,10.1824 C12.428,10.1824 12.788,9.9444 13,9.6054 Z M20,11.1908 C21.163,11.6038 22,12.7028 22,14.0068 C22,15.3108 21.163,16.4098 20,16.8228 L20,21.0008 L18,21.0008 L18,16.8228 C16.837,16.4098 16,15.3108 16,14.0068 C16,12.7028 16.837,11.6038 18,11.1908 L18,3.0008 L20,3.0008 L20,11.1908 Z M20,14.6298 C20.116,14.4448 20.2,14.2398 20.2,14.0068 C20.2,13.7738 20.116,13.5678 20,13.3838 C19.788,13.0448 19.429,12.8068 19,12.8068 C18.571,12.8068 18.212,13.0448 18,13.3838 C17.884,13.5678 17.8,13.7738 17.8,14.0068 C17.8,14.2398 17.884,14.4448 18,14.6298 C18.212,14.9688 18.571,15.2068 19,15.2068 C19.429,15.2068 19.788,14.9688 20,14.6298 Z" />
    </svg>
  );
};

export default FilterIcon;
