import React from 'react';

const IndentIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 3.99994C10.5525 3.99994 11.0002 4.44765 11.0002 4.99994L11.0002 12.9999H19.0002C19.5525 12.9999 20.0002 13.4477 20.0002 13.9999C20.0002 14.5522 19.5525 14.9999 19.0002 14.9999L10.0002 14.9999C9.44791 14.9999 9.00019 14.5522 9.00019 13.9999L9.0002 4.99994C9.0002 4.44765 9.44791 3.99994 10.0002 3.99994Z"
      />
    </svg>
  );
};

export default IndentIcon;
