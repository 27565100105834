// @ts-check
import React from 'react';

const TextInputIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M18 6h4v12h-4v-2h2V8h-2V6zm-4 0v2H4v8h10v2H2V6h12zm3 14h2v2h-6v-2h2V4h-2V2h6v2h-2v16z" />
    </svg>
  );
};

export default TextInputIcon;
