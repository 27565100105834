import React from 'react';

const ClearSmallIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M15.485 7l-3.535 3.535L8.414 7 7 8.414l3.536 3.535L7 15.485l1.414 1.414 3.536-3.536 3.535 3.536 1.414-1.414-3.535-3.536 3.535-3.535z" />
    </svg>
  );
};

export default ClearSmallIcon;
