import React from 'react';

const ZoomIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.8487 18 13.551 17.3729 14.9056 16.3199L20.2928 21.7071C20.6833 22.0977 21.3165 22.0977 21.707 21.7071C22.0975 21.3166 22.0975 20.6835 21.707 20.2929L16.3199 14.9057C17.3729 13.551 18 11.8487 18 10C18 5.58172 14.4183 2 10 2ZM11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7V9H7C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H9V13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13V11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H11V7Z"
      />
    </svg>
  );
};

export default ZoomIcon;
