import React from 'react';

const MoveToInboxIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8531 2.58224C11.5417 2.10019 12.4583 2.10019 13.1469 2.58224L21.1469 8.18224C21.6816 8.5565 22 9.16808 22 9.8207V14C22 14.5523 21.5523 15 21 15C20.4477 15 20 14.5523 20 14V9.8207L12 4.2207L4 9.8207V20H12C12.5523 20 13 20.4478 13 21C13 21.5523 12.5523 22 12 22H4C2.89543 22 2 21.1046 2 20V9.8207C2 9.16807 2.31842 8.5565 2.85308 8.18224L10.8531 2.58224ZM8 17C8 16.4478 8.44772 16 9 16H12C12.5523 16 13 16.4478 13 17C13 17.5523 12.5523 18 12 18H9C8.44772 18 8 17.5523 8 17ZM18.7071 17.7071C19.0976 17.3166 19.0976 16.6834 18.7071 16.2929C18.3166 15.9024 17.6834 15.9024 17.2929 16.2929L15.2929 18.2929C14.9024 18.6834 14.9024 19.3166 15.2929 19.7071L17.2929 21.7071C17.6834 22.0976 18.3166 22.0976 18.7071 21.7071C19.0976 21.3166 19.0976 20.6834 18.7071 20.2929L18.4142 20H21C21.5523 20 22 19.5523 22 19C22 18.4477 21.5523 18 21 18H18.4142L18.7071 17.7071Z"
      />
    </svg>
  );
};

export default MoveToInboxIcon;
