import React from 'react';

const CalendarIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M16,3 L18.001,3 L18.001,5 L21,5 L21,21 L3,21 L3,5 L6,5 L6,3 L8.001,3 L8.001,5 L16,5 L16,3 Z M5,19 L19,19 L19,11 L5,11 L5,19 Z M5,9.001 L19,9.001 L19,7.001 L5,7.001 L5,9.001 Z" />
    </svg>
  );
};

export default CalendarIcon;
