import React from 'react';

const CallIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path d="M18.262,14.861 L16.464,14.608 L14.299,16.774 C11.16,15.367 8.634,12.841 7.227,9.702 L9.394,7.536 L8.756,3 L3.051,3 C3.032,3.333 3,3.662 3,4 C3,13.388 10.612,21 20,21 C20.338,21 20.668,20.968 21.001,20.949 L21.001,15.246 L18.262,14.861 Z" />
    </svg>
  );
};

export default CallIcon;
