import React from 'react';

const TitleIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 3.44772 1.44772 3 2 3H10H18C18.5523 3 19 3.44772 19 4V7C19 7.55228 18.5523 8 18 8C17.4477 8 17 7.55228 17 7V5H11V18H13C13.5523 18 14 18.4477 14 19C14 19.5523 13.5523 20 13 20H10H7C6.44772 20 6 19.5523 6 19C6 18.4477 6.44772 18 7 18H9V5H3V7C3 7.55228 2.55228 8 2 8C1.44772 8 1 7.55228 1 7V4ZM16 20C15.4477 20 15 20.4477 15 21C15 21.5523 15.4477 22 16 22H18H20C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20H19V13H21V15C21 15.5523 21.4477 16 22 16C22.5523 16 23 15.5523 23 15V12C23 11.4477 22.5523 11 22 11H14C13.4477 11 13 11.4477 13 12V15C13 15.5523 13.4477 16 14 16C14.5523 16 15 15.5523 15 15V13H17V20H16Z"
      />
    </svg>
  );
};

export default TitleIcon;
