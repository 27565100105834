import React from 'react';

const DocumentIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8.80599C20 8.28531 19.7969 7.78515 19.434 7.41183L14.7615 2.60584C14.3849 2.21852 13.8677 2 13.3275 2H6ZM12 4L6 4V20H18V10H13C12.4477 10 12 9.55231 12 9.00003V4ZM17.2164 8.00003L14 4.6917V8.00003H17.2164ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14L15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12L9 12ZM8 17C8 16.4477 8.44772 16 9 16L15 16C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H9C8.44772 18 8 17.5523 8 17Z"
      />
    </svg>
  );
};

export default DocumentIcon;
