import React from 'react';

const AgendaLogo = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M 15.203125 16.472656 L 17.523438 16.472656 L 17.523438 14.171875 L 15.203125 14.171875 Z M 15.203125 10.992188 L 17.523438 10.992188 L 17.523438 8.691406 L 15.203125 8.691406 Z M 15.203125 10.992188 " />
      <path d="M 9.824219 6.527344 C 7.003906 6.527344 5.589844 8.226562 5.589844 10.753906 L 5.589844 16.484375 L 7.84375 16.484375 L 7.84375 13.902344 L 11.832031 13.902344 L 11.832031 16.472656 L 14.097656 16.472656 L 14.097656 10.753906 C 14.109375 8.308594 12.5625 6.527344 9.824219 6.527344 Z M 7.84375 10.695312 C 7.84375 9.292969 8.664062 8.660156 9.824219 8.660156 C 10.949219 8.660156 11.84375 9.292969 11.84375 10.695312 L 11.84375 11.773438 L 7.84375 11.773438 Z M 7.84375 10.695312 " />
    </svg>
  );
};

export default AgendaLogo;
