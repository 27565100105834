import React from 'react';

const MoodUnhappyIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M11.99,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 11.99,22 C6.47,22 2,17.52 2,12 C2,6.48 6.47,2 11.99,2 Z M12,20 C16.42,20 20,16.42 20,12 C20,7.58 16.42,4 12,4 C7.58,4 4,7.58 4,12 C4,16.42 7.58,20 12,20 Z M15.5,8 C16.3284271,8 17,8.67157288 17,9.5 C17,10.3284271 16.3284271,11 15.5,11 C14.6715729,11 14,10.3284271 14,9.5 C14,8.67157288 14.6715729,8 15.5,8 Z M8.5,8 C9.32842712,8 10,8.67157288 10,9.5 C10,10.3284271 9.32842712,11 8.5,11 C7.67157288,11 7,10.3284271 7,9.5 C7,8.67157288 7.67157288,8 8.5,8 Z M12,14 C14.33,14 16.32,15.45 17.12,17.5 L15.45,17.5 C14.75,16.31 13.48,15.5 12,15.5 C10.52,15.5 9.24,16.31 8.55,17.5 L6.88,17.5 C7.68,15.45 9.67,14 12,14 Z" />
    </svg>
  );
};

export default MoodUnhappyIcon;
