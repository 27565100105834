// @ts-check
import React from 'react';

const MemoryIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24  24" {...props}>
      <path d="M15 9H9v6h6V9zm-2 4h-2v-2h2v2zm8-2V9h-2V7c0-1.1-.9-2-2-2h-2V3h-2v2h-2V3H9v2H7c-1.1 0-2 .9-2 2v2H3v2h2v2H3v2h2v2c0 1.1.9 2 2 2h2v2h2v-2h2v2h2v-2h2c1.1 0 2-.9 2-2v-2h2v-2h-2v-2h2zm-4 6H7V7h10v10z" />
    </svg>
  );
};

export default MemoryIcon;
