import React from 'react';

const SwitchIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M8.5 2L14 8H11V14H6V8H3L8.5 2ZM13 10H18V16H21L15.5 22L10 16H13V10Z" />
    </svg>
  );
};

export default SwitchIcon;
