import React from 'react';

const MultiChoiceIcon = (props) => {
  return (
    <svg width="24px" height="24px" {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0547 3.16793C6.51423 3.47429 6.6384 4.09516 6.33205 4.55468L4.33205 7.55468C4.16587 7.80396 3.89667 7.9656 3.59854 7.99512C3.3004 8.02464 3.00474 7.91894 2.79289 7.70709L1.29289 6.20709C0.902369 5.81657 0.902369 5.1834 1.29289 4.79288C1.68342 4.40235 2.31658 4.40235 2.70711 4.79288L3.34458 5.43035L4.66795 3.44528C4.9743 2.98576 5.59517 2.86158 6.0547 3.16793ZM8 4.99998C7.44772 4.99998 7 5.4477 7 5.99998C7 6.55227 7.44772 6.99998 8 6.99998H21C21.5523 6.99998 22 6.55227 22 5.99998C22 5.4477 21.5523 4.99998 21 4.99998H8ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H8ZM7 18C7 17.4477 7.44772 17 8 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H8C7.44772 19 7 18.5523 7 18ZM3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H3.01C3.56228 13 4.01 12.5523 4.01 12C4.01 11.4477 3.56228 11 3.01 11H3ZM6.33205 16.5547C6.6384 16.0952 6.51423 15.4743 6.0547 15.1679C5.59517 14.8616 4.9743 14.9858 4.66795 15.4453L3.34458 17.4303L2.70711 16.7929C2.31658 16.4024 1.68342 16.4024 1.29289 16.7929C0.902369 17.1834 0.902369 17.8166 1.29289 18.2071L2.79289 19.7071C3.00474 19.9189 3.3004 20.0246 3.59854 19.9951C3.89667 19.9656 4.16587 19.804 4.33205 19.5547L6.33205 16.5547Z"
      />
    </svg>
  );
};

export default MultiChoiceIcon;
