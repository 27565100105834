import React from 'react';

function UserOffIcon(props) {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7071 1.70711C24.0976 1.31658 24.0976 0.683417 23.7071 0.292893C23.3166 -0.0976311 22.6834 -0.0976311 22.2929 0.292893L0.292893 22.2929C-0.0976311 22.6834 -0.0976311 23.3166 0.292893 23.7071C0.683417 24.0976 1.31658 24.0976 1.70711 23.7071L3.53086 21.8834C3.67077 21.9578 3.83046 22 4 22C4.55228 22 5 21.5523 5 21V20.4142L9.41421 16H16C16.7956 16 17.5587 16.3161 18.1213 16.8787C18.6839 17.4413 19 18.2044 19 19V21C19 21.5523 19.4477 22 20 22C20.5523 22 21 21.5523 21 21V19C21 17.6739 20.4732 16.4021 19.5355 15.4645C18.5979 14.5268 17.3261 14 16 14H11.4142L13.6297 11.7845C13.6484 11.7797 13.6671 11.7744 13.6858 11.7685C15.2443 11.2762 16.4712 10.0434 16.9552 8.48102C16.9584 8.47066 16.9615 8.46028 16.9643 8.44989L23.7071 1.70711ZM7.17848 7C7.17848 4.23858 9.41706 2 12.1785 2C13.0875 2 13.9428 2.24351 14.6792 2.66952C14.8019 2.74047 14.9212 2.81646 15.0369 2.89718C15.4898 3.21321 15.6008 3.83657 15.2848 4.2895C14.9687 4.74243 14.3454 4.85342 13.8924 4.53739C13.8229 4.48889 13.7513 4.44328 13.6778 4.40073C13.2375 4.14607 12.7266 4 12.1785 4C10.5216 4 9.17848 5.34315 9.17848 7C9.17848 7.63775 9.37636 8.22629 9.71408 8.71139C10.0296 9.16465 9.91799 9.78789 9.46473 10.1034C9.01147 10.419 8.38823 10.3074 8.07267 9.8541C7.50906 9.04452 7.17848 8.05936 7.17848 7Z"
      />
    </svg>
  );
}

export default UserOffIcon;
