import React from 'react';

const SunIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M11.9993,5 C8.1333,5 4.9993,8.134 4.9993,12 C4.9993,15.866 8.1333,19 11.9993,19 C15.8653,19 18.9993,15.866 18.9993,12 C18.9993,8.134 15.8653,5 11.9993,5 M11.9993,7 C14.7563,7 16.9993,9.243 16.9993,12 C16.9993,14.757 14.7563,17 11.9993,17 C9.2423,17 6.9993,14.757 6.9993,12 C6.9993,9.243 9.2423,7 11.9993,7 Z M12.9896,4.0674 C12.6646,4.0274 12.3356,4.0004 11.9996,4.0004 C11.6566,4.0004 11.3206,4.0294 10.9896,4.0714 L10.9896,0.0004 L12.9896,0.0004 L12.9896,4.0674 Z M7.0941,5.6943 C6.5661,6.1063 6.0921,6.5813 5.6821,7.1103 L2.8001,4.2283 L4.2141,2.8143 L7.0941,5.6943 Z M4.0677,11.0098 C4.0267,11.3348 3.9997,11.6638 3.9997,11.9998 C3.9997,12.3428 4.0287,12.6788 4.0707,13.0098 L0.0007,13.0098 L0.0007,11.0098 L4.0677,11.0098 Z M5.6937,16.9053 C6.1057,17.4333 6.5817,17.9073 7.1097,18.3173 L4.2287,21.1993 L2.8147,19.7853 L5.6937,16.9053 Z M11.0101,19.9326 C11.3351,19.9726 11.6631,19.9996 11.9991,19.9996 C12.3421,19.9996 12.6781,19.9706 13.0101,19.9286 L13.0101,23.9996 L11.0101,23.9996 L11.0101,19.9326 Z M16.9056,18.3057 C17.4326,17.8937 17.9066,17.4187 18.3176,16.8897 L21.1996,19.7717 L19.7856,21.1857 L16.9056,18.3057 Z M19.932,12.9902 C19.972,12.6652 19.999,12.3362 19.999,12.0002 C19.999,11.6572 19.971,11.3212 19.929,10.9902 L24,10.9902 L24,12.9902 L19.932,12.9902 Z M18.305,7.0947 C17.894,6.5667 17.418,6.0927 16.889,5.6827 L19.771,2.8007 L21.185,4.2147 L18.305,7.0947 Z" />
    </svg>
  );
};

export default SunIcon;
