import React from 'react';

const TextAreaIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4C23 2.34315 21.6569 1 20 1H4ZM3 4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4ZM8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H10H12C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13H11V7H13V8C13 8.55228 13.4477 9 14 9C14.5523 9 15 8.55228 15 8V6C15 5.44772 14.5523 5 14 5H6C5.44772 5 5 5.44772 5 6V8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8V7H9V13H8Z"
      />
    </svg>
  );
};

export default TextAreaIcon;
