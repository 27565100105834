import React from 'react';

const PaperClipIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19.7515 11.2973L12.6598 18.8774C11.791 19.806 10.6127 20.3277 9.38407 20.3277C8.15542 20.3277 6.9771 19.806 6.10832 18.8774C5.23954 17.9488 4.75146 16.6893 4.75146 15.376C4.75146 14.0628 5.23954 12.8033 6.10832 11.8747L13.2 4.29457C13.7792 3.67549 14.5647 3.3277 15.3838 3.3277C16.2029 3.3277 16.9884 3.67549 17.5676 4.29457C18.1468 4.91365 18.4722 5.7533 18.4722 6.62881C18.4722 7.50432 18.1468 8.34397 17.5676 8.96305L10.4683 16.5431C10.1787 16.8527 9.7859 17.0266 9.37635 17.0266C8.9668 17.0266 8.57403 16.8527 8.28443 16.5431C7.99484 16.2336 7.83215 15.8138 7.83215 15.376C7.83215 14.9383 7.99484 14.5184 8.28443 14.2089L14.7171 7.33681"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaperClipIcon;
