// @ts-check
import React from 'react';

const CheckCircleRegularFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12 2C17.5229 2 22 6.47714 22 12C22 17.5229 17.5229 22 12 22C6.47714 22 2 17.5229 2 12C2 6.47714 6.47714 2 12 2ZM14.58 9L10.936 12.644L9.414 11.12L8 12.535L10.936 15.472L15.994 10.414L14.58 9Z" />
    </svg>
  );
};

export default CheckCircleRegularFilledIcon;
