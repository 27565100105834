// @ts-check
import { CSSObject } from '@emotion/core';

import { colors, overlays } from './variants/colors-APP_TARGET';
import { heights } from './variants/heights-APP_TARGET';
import { positions } from './variants/positions-APP_TARGET';
import { navWidth, defaultHeaderHeight, CssRootVariables } from './constants';
import { convertHexToRGB } from 'views/common/utils/colors';

export const FileeePrimaryColor = '#0CA678';

export const DesignSystemColors = {
  white: '#FFFFFF',
  backgrounds: {
    gray50: '#F6F8FA',
    gray100: '#EDF1F5',
    dark: '#3D4E66',
    gray800: '#1D252F'
  },
  outline: {
    low: 'rgba(61, 78, 102, 0.12)',
    high: 'rgba(61, 78, 102, 0.32)',
    highHex: '#C1C5CE',
    error: convertHexToRGB('#df1b41', 0.3)
  },
  fontOnLightBg: {
    high: 'rgba(0,0,0,0.87)',
    medium: 'rgba(0,0,0,0.60)',
    disabled: 'rgba(0,0,0,0.38)'
  },
  fontOnDarkBg: {
    high: '#FFFFFF',
    medium: 'rgba(255,255,255,0.74)',
    disabled: 'rgba(255,255,255,0.38)'
  },
  primary: FileeePrimaryColor,
  interaction: '#087F5B',
  primaryDark: '#05593B',
  primaryUltraLight: '#E0FFED',

  light: {
    gray: '#8792A0',
    blue: '#6593F0',
    cyan: '#0D96F0',
    sky: '#00A3BF',
    lime: '#6BA61E',
    yellow: '#D18400',
    orange: '#E77049',
    red: '#E5657E',
    pink: '#E565A3',
    purple: '#C869D1',
    violet: '#9D83DC'
  },
  main: {
    gray: '#6A7487',
    blue: '#3B6DD1',
    cyan: '#0074CC',
    sky: '#00849E',
    lime: '#548217',
    yellow: '#AD6200',
    orange: '#C84801',
    red: '#DF1B41',
    pink: '#D0326A',
    purple: '#A250B2',
    violet: '#7E5ECC'
  },
  dark: {
    gray: '#3D4E66',
    blue: '#294B8F',
    cyan: '#004B8C',
    sky: '#005769',
    lime: '#335900',
    yellow: '#6E4500',
    orange: '#8A3201',
    red: '#942023',
    pink: '#9C0B3E',
    purple: '#7A298A',
    violet: '#56399E'
  }
};

export const DesignSystemOverlayColors = {
  primary: {
    default: convertHexToRGB(DesignSystemColors.interaction, 0.1),
    hover: convertHexToRGB(DesignSystemColors.interaction, 0.16),
    focused: convertHexToRGB(DesignSystemColors.interaction, 0.24),
    pressed: convertHexToRGB(DesignSystemColors.interaction, 0.28),
    dragged: convertHexToRGB(DesignSystemColors.interaction, 0.08),
    selected: convertHexToRGB(DesignSystemColors.interaction, 0.08)
  },
  black: {
    default: convertHexToRGB(DesignSystemColors.dark.gray, 0.04),
    hover: convertHexToRGB(DesignSystemColors.dark.gray, 0.06),
    focused: convertHexToRGB(DesignSystemColors.dark.gray, 0.12),
    pressed: convertHexToRGB(DesignSystemColors.dark.gray, 0.16),
    dragged: convertHexToRGB(DesignSystemColors.dark.gray, 0.08),
    selected: convertHexToRGB(DesignSystemColors.dark.gray, 0.08)
  },
  white: {
    hover: convertHexToRGB(DesignSystemColors.white, 0.16),
    focused: convertHexToRGB(DesignSystemColors.white, 0.24),
    pressed: convertHexToRGB(DesignSystemColors.white, 0.28),
    dragged: convertHexToRGB(DesignSystemColors.white, 0.12),
    selected: convertHexToRGB(DesignSystemColors.white, 0.12)
  },
  light: {
    orange: convertHexToRGB(DesignSystemColors.light.orange, 0.08),
    red: convertHexToRGB(DesignSystemColors.light.red, 0.08),
    pink: convertHexToRGB(DesignSystemColors.light.pink, 0.08),
    purple: convertHexToRGB(DesignSystemColors.light.purple, 0.08),
    violet: convertHexToRGB(DesignSystemColors.light.violet, 0.08)
  },
  main: {
    orange: convertHexToRGB(DesignSystemColors.main.orange, 0.08),
    red: convertHexToRGB(DesignSystemColors.main.red, 0.08),
    pink: convertHexToRGB(DesignSystemColors.main.pink, 0.08),
    purple: convertHexToRGB(DesignSystemColors.main.purple, 0.08),
    violet: convertHexToRGB(DesignSystemColors.main.violet, 0.08),
    yellow: convertHexToRGB(DesignSystemColors.main.yellow, 0.08)
  },
  dark: {
    orange: convertHexToRGB(DesignSystemColors.dark.orange, 0.08),
    red: convertHexToRGB(DesignSystemColors.dark.red, 0.08),
    pink: convertHexToRGB(DesignSystemColors.dark.pink, 0.08),
    purple: convertHexToRGB(DesignSystemColors.dark.purple, 0.08),
    violet: convertHexToRGB(DesignSystemColors.dark.violet, 0.08)
  }
};

export const DesignSystemWarningColors = {
  red: DesignSystemColors.main.red,
  redOverlay: DesignSystemOverlayColors.main.red,
  orange: DesignSystemColors.light.orange,
  orangeOverlay: DesignSystemOverlayColors.light.orange
};

export const DesignSystemDarkModeColors = {
  primary: '#57D9A5',
  interaction: '#93EDC0'
};

export const theme = {
  colors: {
    primary: DesignSystemColors.primary,
    onPrimary: DesignSystemColors.white,
    onSecondary: DesignSystemColors.white,
    primaryDark: DesignSystemColors.primaryDark,
    primary15: DesignSystemOverlayColors.primary.default,
    primary30: DesignSystemOverlayColors.primary.focused,
    /**
     * TODO Replace usage with interaction
     */
    primary700: DesignSystemColors.interaction,
    secondary: DesignSystemColors.light.blue,
    /**
     * 200 and 400 are same. Remove 400 usages later
     */ secondary200: DesignSystemColors.light.cyan,
    secondary300: DesignSystemColors.light.cyan,
    secondary400: DesignSystemColors.light.cyan,
    secondary700: DesignSystemColors.main.cyan,
    secondary800: DesignSystemColors.dark.blue,
    interaction: DesignSystemColors.interaction,
    premium: DesignSystemColors.light.cyan,
    professional: DesignSystemColors.main.blue,
    contentBg: DesignSystemColors.backgrounds.gray100,
    contentBg2: DesignSystemColors.backgrounds.gray50,
    headerBg: DesignSystemColors.primary,
    onHeader: DesignSystemColors.white,
    headerIconFill: DesignSystemColors.white,
    logoBackground: DesignSystemColors.white,
    logoText: DesignSystemColors.primary,
    text1: DesignSystemColors.fontOnLightBg.high,
    text2: DesignSystemColors.fontOnLightBg.medium,
    text3: DesignSystemColors.fontOnLightBg.disabled,
    text4: 'rgba(0, 0, 0, 0.12)',
    text5: 'rgba(0, 0, 0, 0.06)',
    text6: 'rgba(0, 0, 0, 0.04)',
    light: DesignSystemColors.white,
    light1: DesignSystemColors.fontOnDarkBg.high,
    light2: DesignSystemColors.fontOnDarkBg.high,
    light3: DesignSystemColors.fontOnDarkBg.medium,
    light4: DesignSystemColors.fontOnDarkBg.disabled,
    grey40: DesignSystemColors.backgrounds.gray100,
    grey50: DesignSystemColors.backgrounds.gray100,
    grey60: DesignSystemColors.light.gray,
    grey70: DesignSystemColors.main.gray,
    grey80: DesignSystemColors.dark.gray,
    grey90: DesignSystemColors.dark.gray,
    grey100: DesignSystemColors.backgrounds.gray50,
    grey200: DesignSystemColors.backgrounds.gray100,
    grey300: DesignSystemColors.backgrounds.gray100,
    grey400: DesignSystemColors.backgrounds.gray100,
    grey500: DesignSystemColors.light.gray,
    grey600: DesignSystemColors.main.gray,
    grey700: DesignSystemColors.dark.gray,
    grey800: DesignSystemColors.dark.gray,
    grey900: DesignSystemColors.backgrounds.gray800,
    grey1000: DesignSystemColors.backgrounds.gray800,
    grey110: DesignSystemColors.backgrounds.gray800,
    grey120: DesignSystemColors.backgrounds.gray800,
    grey130: DesignSystemColors.backgrounds.gray800,
    grey140: DesignSystemColors.backgrounds.gray800,
    lightBlue1: DesignSystemColors.light.cyan,
    infoBlue: '#DEF3FD',
    selectionColor: DesignSystemColors.main.cyan,
    notification: DesignSystemColors.light.orange,
    warning: DesignSystemWarningColors.orange,
    error: DesignSystemWarningColors.red,
    thinkOwlPrimary: '#049D96',
    surface: DesignSystemColors.white,
    orange: DesignSystemColors.main.orange,
    violet: DesignSystemColors.main.violet,
    darkViolet: DesignSystemColors.dark.violet,
    lightYellow: DesignSystemColors.light.yellow,
    mainYellow: DesignSystemColors.main.yellow,
    accountTypes: {
      free: {
        light: DesignSystemColors.primary,
        main: DesignSystemColors.interaction,
        dark: DesignSystemColors.primaryDark
      },
      premium: {
        light: DesignSystemColors.light.cyan,
        main: DesignSystemColors.main.cyan,
        dark: DesignSystemColors.dark.cyan
      },
      professional: {
        light: DesignSystemColors.light.blue,
        main: DesignSystemColors.main.blue,
        dark: DesignSystemColors.dark.blue
      },
      premiumFamily: {
        light: DesignSystemColors.light.violet,
        main: DesignSystemColors.main.violet,
        dark: DesignSystemColors.dark.violet
      },
      businessFree: {
        light: DesignSystemColors.primary,
        main: DesignSystemColors.interaction,
        dark: DesignSystemColors.primaryDark
      },
      businessPremium: {
        light: DesignSystemColors.light.cyan,
        main: DesignSystemColors.main.cyan,
        dark: DesignSystemColors.dark.cyan
      },
      businessProfessional: {
        light: DesignSystemColors.light.blue,
        main: DesignSystemColors.main.blue,
        dark: DesignSystemColors.dark.blue
      },
      businessEnterprise: {
        light: DesignSystemColors.main.gray,
        main: DesignSystemColors.dark.gray,
        dark: DesignSystemColors.backgrounds.gray800
      },
      newFree: {
        dark: DesignSystemColors.interaction,
        light: DesignSystemColors.primary
      },
      newBusinessFree: {
        dark: DesignSystemColors.primaryDark,
        light: DesignSystemColors.primary
      },
      basic: {
        dark: DesignSystemColors.main.cyan,
        light: DesignSystemColors.light.cyan
      },
      smart: {
        dark: DesignSystemColors.main.blue,
        light: DesignSystemColors.light.blue
      },
      family: {
        dark: DesignSystemColors.main.violet,
        light: DesignSystemColors.light.violet
      },
      newPremium: {
        dark: DesignSystemColors.dark.cyan,
        main: DesignSystemColors.main.cyan,
        light: DesignSystemColors.light.cyan
      },
      newProfessional: {
        dark: DesignSystemColors.dark.blue,
        main: DesignSystemColors.main.blue,
        light: DesignSystemColors.light.blue
      },
      newEnterprise: {
        dark: DesignSystemColors.dark.gray,
        main: DesignSystemColors.light.gray,
        light: '#6A7487'
      }
    },
    fillIndicator: {
      level0: '#4BBAB5',
      level1: '#AFD685',
      level2: '#FAF685',
      level3: '#ED7C68'
    },
    ...colors
  },
  rgba: {
    inputBgColor: DesignSystemOverlayColors.black.hover,
    borderColor: DesignSystemOverlayColors.black.focused,
    grey700_32: DesignSystemColors.fontOnLightBg.disabled,
    grey700_04: DesignSystemOverlayColors.black.default,
    grey700_06: DesignSystemOverlayColors.black.hover,
    grey700_08: DesignSystemOverlayColors.black.selected
  },
  heights: {
    headerHeight: defaultHeaderHeight,
    mobileAppHeight: `calc(var(${CssRootVariables.mobileViewportHeight}, 1vh) * 100)`,
    appHeight: '100vh',
    ...heights
  },
  positions: {
    leftNavigationTopPosition: defaultHeaderHeight,
    headerLeftPosition: 87,
    ...positions
  },
  widths: {
    navWidth: navWidth,
    navLinkExpanded: 256,
    rightNav: ['100vw', 350, 400, 472, 540],
    documentLibraryRightNav: 360,
    companyLibraryRightNav: 360
  },
  zIndex: {
    rightNav: 5,
    header: 11,
    leftNavOverlay: 10,
    leftNav: 11,
    headerDropdown: 600,
    popper: 999 /* Note: to be able to show when react modal is open as well */,
    popperInModal: 1300,
    documentLibraryOverlay: 2,
    modal: 1000
  },
  shadows: {
    elevation4: `0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.08), 0px 8px 8px 0px rgba(0, 0, 0, 0.08)`,
    elevation8: `0px 8px 16px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.08)`,
    elevation16: `0px 24px 32px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08), 0px 8px 8px rgba(0, 0, 0, 0.04)`,
    elevation32: `0px 32px 48px rgba(0, 0, 0, 0.08), 0px 24px 32px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 8px 8px rgba(0, 0, 0, 0.04)`
  },
  overlays: {
    primary: DesignSystemOverlayColors.primary.default,
    primary_hover: DesignSystemOverlayColors.primary.hover,
    primary_focused: DesignSystemOverlayColors.primary.focused,
    primary_pressed: DesignSystemOverlayColors.primary.pressed,
    primary_selected: DesignSystemOverlayColors.primary.selected,
    black: DesignSystemOverlayColors.black.default,
    black_hover: DesignSystemOverlayColors.black.hover,
    black_focused: DesignSystemOverlayColors.black.focused,
    black_pressed: DesignSystemOverlayColors.black.pressed,
    black_selected: DesignSystemOverlayColors.black.selected,
    white_hover: DesignSystemOverlayColors.white.hover,
    white_focused: DesignSystemOverlayColors.white.focused,
    white_pressed: DesignSystemOverlayColors.white.pressed,
    white_selected: DesignSystemOverlayColors.white.selected,
    red: DesignSystemOverlayColors.main.red,
    orange: DesignSystemOverlayColors.light.orange,
    ...overlays
  },
  outline: {
    ...DesignSystemColors.outline
  }
}; // pink red in SCSS file // We overwrite default colors with branding colors

export type ThemeType = typeof theme;

export const Elevations = [
  `0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)`,
  `0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)`,
  `0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12)`,
  `0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12)`
];

export function withSpecificty(trueObj: CSSObject = {}): CSSObject {
  if (BUILD_VAR_IS_EMBEDDED_APP) {
    return {
      ['&&&&&']: trueObj
    };
  }
  return trueObj;
}

export function withForcedSpecificty(trueObj: CSSObject = {}): CSSObject {
  return {
    ['&&&&']: trueObj
  };
}

export function onCondition(booleanValue, trueObj = {}) {
  if (booleanValue) return trueObj;
  else return {};
}

export function getFullPageHeight(offset = '0px') {
  return `calc(${theme.heights.appHeight} - ${offset}) !important`;
}

export function getHeightExcludingHeader(offset = '0px') {
  return `calc(${theme.heights.appHeight} - ${theme.heights.headerHeight}px - ${offset})!important`;
}

export function getMobileHeightExcludingHeader(offset = '0px') {
  return `calc(${theme.heights.mobileAppHeight} - ${theme.heights.headerHeight}px - ${offset})!important`;
}

export function getRelativeHeightExcludingHeader(offset = '0px') {
  return `calc(100% - ${theme.heights.headerHeight}px - ${offset})!important`;
}

export function getSvgColorCss(color) {
  return {
    ['path']: {
      fill: color
    },
    ['rect']: {
      fill: color
    },
    ['polygon']: {
      fill: color
    },
    ['circle']: {
      fill: color
    }
  };
}

export function getSvgStrokeColorCss(color) {
  return {
    ['path']: {
      stroke: color
    },
    ['rect']: {
      stroke: color
    },
    ['polygon']: {
      stroke: color
    },
    ['circle']: {
      stroke: color
    }
  };
}

export function getSvgStrokeColorWithoutFillCss(color) {
  return {
    ['path']: {
      stroke: color,
      fill: 'transparent!important'
    },
    ['rect']: {
      stroke: color,
      fill: 'transparent!important'
    },
    ['polygon']: {
      stroke: color,
      fill: 'transparent!important'
    },
    ['circle']: {
      stroke: color,
      fill: 'transparent!important'
    }
  };
}

/**
 *
 * Breakpoints specific code follows
 *
 */

export const breakPointMap = new Map([
  ['sm', 568],
  ['md', 768],
  ['lg', 1024],
  ['xl', 1280],
  ['xxl', 1920]
]);
const mq = (bp) => `@media (min-width: ${bp}px)`;

export type MinWidthBreakPointType = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export function onMq(breakpoint: MinWidthBreakPointType = 'sm', cssObj: CSSObject = {}): CSSObject {
  return {
    [mq(breakPointMap.get(breakpoint))]: cssObj
  };
}

export function onMqInPx(breakpoint: number, cssObj: CSSObject = {}): CSSObject {
  return {
    [`@media (min-width: ${breakpoint}px)`]: cssObj
  };
}

type LineClampObj = {
  display: string;
  WebkitLineClamp: number;
  WebkitBoxOrient: 'vertical';
  overflow: string;
};
export function getLineClamp(lineNumber: number = 2): LineClampObj {
  return {
    display: '-webkit-box',
    WebkitLineClamp: lineNumber,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  };
}

export function getLineClampFromVariable(name: string) {
  return {
    display: '-webkit-box',
    WebkitLineClamp: `var(${name})`,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  };
}

export function getCssValueForVariablesWithFallback(variables: string[] = [], fallback: string) {
  if (variables.length === 0) return fallback;

  let finalValue = fallback;

  variables.forEach((variable) => {
    finalValue = getCssValueForSingleVariableWithFallback(variable, finalValue);
  });

  return finalValue;
}

function getCssValueForSingleVariableWithFallback(variable, fallback) {
  if (!variable) return fallback;

  return `var(${variable}, ${fallback})`;
}
