import React from 'react';

const LockIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M17.001,9 L21.001,9 L21,22 L3,22 L3.001,9 L7.001,9 L7.001,6.677 C7.17,4.069 9.35,2 12,2 C14.65,2 16.831,4.069 17.001,6.677 L17.001,9 Z M9,7 L9,9 L15,9 L15,7 C15,5.346 13.654,4 12,4 C10.347,4 9.002,5.344 9,7 Z M12,17.4111 C12.553,17.4111 13,16.9641 13,16.4111 L13,14.4111 C13,13.8581 12.553,13.4111 12,13.4111 C11.447,13.4111 11,13.8581 11,14.4111 L11,16.4111 C11,16.9641 11.447,17.4111 12,17.4111 Z" />
    </svg>
  );
};

export default LockIcon;
