import React from 'react';

const DocumentSearchIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H12C12.5523 22 13 21.5523 13 21C13 20.4477 12.5523 20 12 20H4V4L10 4V9C10 9.55228 10.4477 10 11 10H16V12C16 12.5523 16.4477 13 17 13C17.5523 13 18 12.5523 18 12V8.80599C18 8.28531 17.7969 7.78515 17.434 7.41183L12.7615 2.60584C12.3849 2.21852 11.8677 2 11.3275 2H4ZM15.2164 8L12 4.6917V8H15.2164ZM7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14L13 14C13.5523 14 14 13.5523 14 13C14 12.4477 13.5523 12 13 12L7 12ZM11 18L7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44771 16 7 16L11 16C11.5523 16 12 16.4477 12 17C12 17.5523 11.5523 18 11 18ZM17.5 16C16.6716 16 16 16.6716 16 17.5C16 18.3284 16.6716 19 17.5 19C18.3284 19 19 18.3284 19 17.5C19 16.6716 18.3284 16 17.5 16ZM14 17.5C14 15.567 15.567 14 17.5 14C19.433 14 21 15.567 21 17.5C21 18.1028 20.8476 18.6699 20.5793 19.1651L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L19.1651 20.5793C18.6699 20.8476 18.1028 21 17.5 21C15.567 21 14 19.433 14 17.5Z"
      />
    </svg>
  );
};

export default DocumentSearchIcon;
