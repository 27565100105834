import React from 'react';

const PersonFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M12,14 C16.418,14 20,16.6847382 20,19.9964 L4,19.9964 C4,16.6847382 7.582,14 12,14 Z M16,8 C16,10.21 14.209,12 12,12 C9.791,12 8,10.21 8,8 C8,5.791 9.791,4 12,4 C14.209,4 16,5.791 16,8 Z"
        id="path-1"
      />
    </svg>
  );
};

export default PersonFilledIcon;
