import React from 'react';

function ConnectorIcon(props) {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M5 2c0-.55-.45-1-1-1s-1 .45-1 1v4H1v10c0 1.3.84 2.4 2 2.82V23h2v-4.18C6.16 18.4 7 17.3 7 16V6H5V2zM4 17c-.55 0-1-.45-1-1v-2h2v2c0 .55-.45 1-1 1zM3 8v4h2V8H3zm10-6c0-.55-.45-1-1-1s-1 .45-1 1v4H9v10c0 1.3.84 2.4 2 2.82V23h2v-4.18c1.16-.42 2-1.52 2-2.82V6h-2V2zm-2 14c0 .55.45 1 1 1s1-.45 1-1v-2h-2v2zm0-4V8h2v4h-2zM21 2v4h2v10c0 1.3-.84 2.4-2 2.82V23h-2v-4.18c-1.16-.42-2-1.52-2-2.82V6h2V2c0-.55.45-1 1-1s1 .45 1 1zm-1 15c-.55 0-1-.45-1-1v-2h2v2c0 .55-.45 1-1 1zm-1-9v4h2V8h-2z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ConnectorIcon;
