import React from 'react';

const MinimizeIcon = (props) => {
  return (
    <svg width="24px" height="24px" {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2929 2.29289C20.6834 1.90237 21.3166 1.90237 21.7071 2.29289C22.0976 2.68342 22.0976 3.31658 21.7071 3.70711L16.4142 9H20C20.5523 9 21 9.44772 21 10C21 10.5523 20.5523 11 20 11H14.0007H14C13.999 11 13.998 11 13.997 11C13.8625 10.9996 13.7343 10.9727 13.6172 10.9241C13.5001 10.8757 13.3904 10.804 13.295 10.7092C13.2936 10.7078 13.2922 10.7064 13.2908 10.705C13.0977 10.5108 13.0008 10.257 13 10.003C13 10.002 13 10.001 13 10V9.99931V4C13 3.44772 13.4477 3 14 3C14.5523 3 15 3.44772 15 4V7.58579L20.2929 2.29289ZM10.9241 13.6172C10.8757 13.5001 10.804 13.3904 10.7092 13.295L10.705 13.2908C10.5108 13.0977 10.257 13.0008 10.003 13L10 13H9.99931H4C3.44772 13 3 13.4477 3 14C3 14.5523 3.44772 15 4 15H7.58579L2.29289 20.2929C1.90237 20.6834 1.90237 21.3166 2.29289 21.7071C2.68342 22.0976 3.31658 22.0976 3.70711 21.7071L9 16.4142V20C9 20.5523 9.44772 21 10 21C10.5523 21 11 20.5523 11 20V14.0007V14L11 13.997C10.9996 13.8625 10.9727 13.7343 10.9241 13.6172Z"
      />
    </svg>
  );
};

export default MinimizeIcon;
