import React from 'react';

const TwitterIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        id="ic_twitter-a"
        d="M19.15,8.74 C19.15,8.9 19.15,9.07 19.15,9.23 C19.15,14.23 15.44,20 8.65,20 C6.64057935,20.0005302 4.67549932,19.4092672 3,18.3 C3.29640229,18.3177124 3.59359771,18.3177124 3.89,18.3 C5.55740168,18.3019661 7.17466342,17.7299216 8.47,16.68 C6.88258751,16.6540374 5.48762928,15.6208839 5,14.11 C5.23137695,14.1485001 5.46544619,14.1685632 5.7,14.17 C6.0376284,14.1712842 6.37392212,14.127566 6.7,14.04 C4.94296064,13.6774191 3.68683628,12.124012 3.7,10.33 C4.2097549,10.6224691 4.78274654,10.7871613 5.37,10.81 C3.72117172,9.65410896 3.23789508,7.42494101 4.26,5.69 C6.1010517,8.058476 8.87515664,9.51661263 11.87,9.69 C11.8104386,9.40382352 11.7802819,9.11230884 11.78,8.82 C11.7585791,7.8246234 12.1349198,6.86178757 12.8256586,6.14477062 C13.5163975,5.42775368 14.4645174,5.01574184 15.46,5 C16.4891734,5.00031413 17.4701146,5.43628798 18.16,6.2 C18.987687,6.03746811 19.7799679,5.72935885 20.5,5.29 C20.2316916,6.16026357 19.6558638,6.90315257 18.88,7.38 C19.6131073,7.28917279 20.3280275,7.08683688 21,6.78 C20.4978629,7.53265664 19.8724323,8.19527504 19.15,8.74 Z"
      />
    </svg>
  );
};

export default TwitterIcon;
