import React from 'react';

const AddContactIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path d="M4 3.9997H11V5.9997H6V7.9997H7V9.9997H6V11.9997H7V13.9997H6V15.9997H7V17.9997H6V19.9997H16V10.9997H18V21.9997H4V17.9997H3V15.9997H4V13.9997H3V11.9997H4V9.9997H3V7.9997H4V3.9997ZM15.9997 1H18.0007V4H20.9997V5.999H18.0007V9H15.9997V5.999H12.9997V4H15.9997V1ZM8.0001 11.9997V7.9997H14.0001V11.9997H8.0001Z" />
    </svg>
  );
};

export default AddContactIcon;
