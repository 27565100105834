import React from 'react';

const SignatureIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M2 17.4444C3.48148 15.4074 6.44444 12.4444 7.55556 8.55556C8.37746 5.67891 7.55556 3 5.88889 3C4.22222 3 2 5.5 2 9.11111C2 11.8889 2.55556 15.7778 5.88889 15.7778C9.22222 15.7778 13.1111 11.3333 13.6667 10.2222C15.7326 6.09033 10.8889 5.77778 9.77778 10.7778C9.29571 12.9471 10.8329 15.5668 12.5556 15.2222C15.3333 14.6667 17.5556 11.8889 17.5556 11.8889C17.5556 11.8889 17.8002 15.2222 18.6667 15.2222C19.5332 15.2222 20.3333 13 22 13"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 21H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SignatureIcon;
