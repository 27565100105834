import React from 'react';

const LightningFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M13.9923 2.12413C14.0498 1.66393 13.7834 1.22458 13.3487 1.06286C12.914 0.901142 12.4252 1.05951 12.168 1.4454L4.16795 13.4454C3.96338 13.7523 3.94431 14.1468 4.11833 14.472C4.29235 14.7971 4.63121 15.0001 5 15.0001H10.8672L10.0077 21.8761C9.9502 22.3363 10.2166 22.7756 10.6513 22.9373C11.086 23.099 11.5748 22.9407 11.8321 22.5548L19.8321 10.5548C20.0366 10.2479 20.0557 9.8534 19.8817 9.52824C19.7077 9.20308 19.3688 9.0001 19 9.0001H13.1328L13.9923 2.12413Z" />
    </svg>
  );
};

export default LightningFilledIcon;
