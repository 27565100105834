// @ts-check
import React from 'react';

const KeyboardArrowDownIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon id="path-1" points="18.581 7 11.999 13.581 5.419 7 4 8.418 11.999 16.419 20 8.418" />
    </svg>
  );
};

export default KeyboardArrowDownIcon;
