// @ts-check
import React from 'react';

const PersonAddIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M9,4 C6.791,4 5,5.791 5,8 C5,10.209 6.791,12 9,12 C11.209,12 13,10.209 13,8 C13,5.791 11.209,4 9,4 M9,12.9848 C4.582,12.9848 1,16.1238 1,19.9948 L17,19.9948 C17,16.1238 13.418,12.9848 9,12.9848 M9,14.9848 C11.457,14.9848 13.574,16.2248 14.501,17.9948 L3.499,17.9948 C4.426,16.2248 6.543,14.9848 9,14.9848 M9,6 C10.103,6 11,6.897 11,8 C11,9.103 10.103,10 9,10 C7.897,10 7,9.103 7,8 C7,6.897 7.897,6 9,6 Z M23,9.999 L23,11.999 L20,11.999 L20,14.999 L18,14.999 L18,11.999 L15,11.999 L15,9.999 L18,9.999 L18,6.999 L20,6.999 L20,9.999 L23,9.999 Z" />
    </svg>
  );
};

export default PersonAddIcon;
