// @ts-check
import React from 'react';

const DocumentCheckIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C4 2.89543 4.89543 2 6 2H13.3275C13.8677 2 14.3849 2.21852 14.7615 2.60584L19.434 7.41183C19.7969 7.78515 20 8.28531 20 8.80599V14C20 14.5523 19.5523 15 19 15C18.4477 15 18 14.5523 18 14V10H13C12.4477 10 12 9.55231 12 9.00003V4L6 4V20H12C12.5523 20 13 20.4477 13 21C13 21.5523 12.5523 22 12 22H6C4.89543 22 4 21.1046 4 20V4ZM14 4.6917L17.2164 8.00003H14V4.6917ZM8 13C8 12.4477 8.44772 12 9 12L15 12C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14L9 14C8.44772 14 8 13.5523 8 13ZM9 16C8.44772 16 8 16.4477 8 17C8 17.5523 8.44771 18 9 18L12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16L9 16ZM17.7071 21.7071L21.7071 17.7071C22.0976 17.3166 22.0976 16.6834 21.7071 16.2929C21.3166 15.9024 20.6834 15.9024 20.2929 16.2929L17 19.5858L15.7071 18.2929C15.3166 17.9024 14.6834 17.9024 14.2929 18.2929C13.9024 18.6834 13.9024 19.3166 14.2929 19.7071L16.2929 21.7071C16.6834 22.0976 17.3166 22.0976 17.7071 21.7071Z"
      />
    </svg>
  );
};

export default DocumentCheckIcon;
