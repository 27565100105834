import React from 'react';

const IdIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M9 14C9.39105 14.3166 9.85782 14.5681 10.373 14.7398C10.8881 14.9116 11.4412 15 12 15C12.5588 15 13.1119 14.9116 13.627 14.7398C14.1422 14.5681 14.6089 14.3166 15 14"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 9H9.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 9H15.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 7V5C3 3.89543 3.89543 3 5 3H7" strokeWidth="2" strokeLinecap="round" />
      <path d="M21 17V19C21 20.1046 20.1046 21 19 21H17" strokeWidth="2" strokeLinecap="round" />
      <path d="M3 17V19C3 20.1046 3.89543 21 5 21H7" strokeWidth="2" strokeLinecap="round" />
      <path d="M21 7V5C21 3.89543 20.1046 3 19 3H17" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default IdIcon;
