import React from 'react';

const InfoCircleIconFilled = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" {...props}>
      <path d="M10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0ZM9 15H11V9H9V15ZM9 7H11V5H9V7Z" />
    </svg>
  );
};

export default InfoCircleIconFilled;
