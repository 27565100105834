import React from 'react';

const SearchIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M15.5558563,14.2588119 L21,19.7034741 L19.7024548,21 L14.2585286,15.5560738 C13.0787419,16.4616951 11.6022361,17 10,17 C6.134,17 3,13.866 3,10 C3,6.134 6.134,3 10,3 C13.866,3 17,6.134 17,10 C17,11.6023644 16.4616089,13.0789782 15.5558563,14.2588119 Z M15,10 C15,7.239 12.761,5 10,5 C7.239,5 5,7.239 5,10 C5,12.761 7.239,15 10,15 C12.761,15 15,12.761 15,10 Z" />
    </svg>
  );
};

export default SearchIcon;
