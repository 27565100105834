import React from 'react';

const LinkedInIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fill="#2C2A38"
        d="M3.478 2C2.662 2 2 2.642 2 3.433v17.135C2 21.358 2.662 22 3.478 22h17.044c.816 0 1.478-.641 1.478-1.432V3.433C22 2.642 21.338 2 20.522 2H3.478zm4.6 7.733v9.004H5.085V9.733h2.993zm.197-2.784c0 .864-.65 1.555-1.693 1.555h-.02c-1.004 0-1.654-.691-1.654-1.555 0-.884.67-1.556 1.694-1.556 1.023 0 1.653.672 1.673 1.556zm4.452 11.788H9.734s.04-8.159 0-9.003h2.993v1.274c.398-.613 1.11-1.486 2.697-1.486 1.97 0 3.446 1.287 3.446 4.053v5.162h-2.993v-4.816c0-1.21-.433-2.036-1.516-2.036-.827 0-1.32.557-1.536 1.095-.079.191-.098.46-.098.73v5.027z"
      />
    </svg>
  );
};

export default LinkedInIcon;
