import React from 'react';

const HintIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24  24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17ZM9.93923 9.34333C10.1366 8.8032 10.7158 8.34071 11.492 8.17689C12.2685 8.013 12.9653 8.20514 13.3457 8.61608C13.8132 9.12098 13.9412 9.71305 13.7609 10.2605C13.5753 10.8241 12.9928 11.5388 11.6362 12.0686C11.1217 12.2695 10.8676 12.8495 11.0685 13.3639C11.2695 13.8783 11.8494 14.1325 12.3638 13.9315C14.0895 13.2575 15.2336 12.1824 15.6605 10.8862C16.0928 9.57379 15.7195 8.23614 14.8134 7.25738C13.8203 6.18467 12.325 5.95703 11.079 6.22C9.83267 6.48304 8.55882 7.29405 8.06077 8.65678C7.87118 9.17551 8.138 9.74971 8.65672 9.93929C9.17545 10.1289 9.74965 9.86206 9.93923 9.34333Z"
      />
    </svg>
  );
};

export default HintIcon;
