// @ts-check
import React from 'react';

const DragVertical = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M13 9V7h2.001v2H13zM9 9V7h2v2H9zm4 4v-2.001h2.001V13H13zm-4 0v-2.001h2V13H9zm4 4v-2h2.001v2H13zm-4 0v-2h2v2H9z" />
    </svg>
  );
};

export default DragVertical;
