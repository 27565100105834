//@ts-check

// inspired from http://tdekoning.github.io/rgba-converter/
class Color {
  red;
  green;
  blue;
  alpha;
  constructor(red, green, blue, alpha) {
    this.red = red;
    this.green = green;
    this.blue = blue;
    this.alpha = alpha;
  }

  toHex() {
    return this.getHexValue(this.red) + this.getHexValue(this.green) + this.getHexValue(this.blue);
  }

  getHexValue(intInput) {
    let result = intInput.toString(16);
    if (result.length < 2) {
      result = '0' + result;
    }
    return result;
  }
}

function convertToHex(color, bgColor) {
  let alpha = color.alpha;

  function getTintValue(tint, bgTint) {
    let tmp = Math.floor((1 - alpha) * bgTint + alpha * tint);
    if (tmp > 255) {
      return 255;
    }
    return tmp;
  }

  return new Color(
    getTintValue(color.red, bgColor.red),
    getTintValue(color.green, bgColor.green),
    getTintValue(color.blue, bgColor.blue)
  );
}

function parseRgba(rgba) {
  // Strip the rgba-definition off the string.
  rgba = rgba.replace('rgba(', '').replace(')', '').replace(' ', '');

  // Split the rgba string into an array.
  var splittedRgba = rgba.split(',');

  return new Color(
    parseInt(splittedRgba[0], 10),
    parseInt(splittedRgba[1], 10),
    parseInt(splittedRgba[2], 10),
    parseFloat(splittedRgba[3]) || 1
  );
}

/**
 *
 *
 * @export
 * @param {string} rgba
 * @param {string} [backgroundColor='rgba(255, 255, 255, 1)']
 * @returns
 */
export function convertRgbaToHex(rgba, backgroundColor = 'rgba(255, 255, 255, 1)') {
  let rgbaColor = parseRgba(rgba);
  let bgColor = parseRgba(backgroundColor);

  if (rgbaColor.alpha < 1) {
    return '#' + convertToHex(rgbaColor, bgColor).toHex();
  } else {
    return '#' + rgbaColor.toHex();
  }
}

const HEX_REGEX = /^[0-9A-F]{6}$/i;

/**
 *
 * @param {string} [hexValue]
 * @param {number} withOpacity
 */
export const convertHexToRGB = (hexValue, withOpacity = 0.15) => {
  if (!hexValue || hexValue.length == 0) {
    return '';
  }
  if (hexValue.charAt(0) === '#') {
    hexValue = hexValue.substring(1);
  }
  if (!HEX_REGEX.test(hexValue)) {
    return '';
  }
  let r = parseInt(hexValue.substring(0, 2), 16);
  let g = parseInt(hexValue.substring(2, 4), 16);
  let b = parseInt(hexValue.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${withOpacity})`;
};

/**
 *
 * @param {string} [hexValue]
 */
export const convertHexToRGBColorSpace = (hexValue) => {
  if (!hexValue || hexValue.length == 0) {
    return '';
  }
  if (hexValue.charAt(0) === '#') {
    hexValue = hexValue.substring(1);
  }
  if (!HEX_REGEX.test(hexValue)) {
    return '';
  }
  let r = parseInt(hexValue.substring(0, 2), 16);
  let g = parseInt(hexValue.substring(2, 4), 16);
  let b = parseInt(hexValue.substring(4, 6), 16);
  return `${r},${g},${b}`;
};
