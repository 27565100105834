import React from 'react';

const MergeIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4ZM2 5C2 3.34315 3.34315 2 5 2C6.30622 2 7.41746 2.83481 7.82929 4H9C10.6569 4 12 5.34315 12 7V10C12 10.5523 12.4477 11 13 11H14V9C14 8.44772 14.4477 8 15 8H21C21.5523 8 22 8.44772 22 9V15C22 15.5523 21.5523 16 21 16H15C14.4477 16 14 15.5523 14 15V13H13C12.4477 13 12 13.4477 12 14V17C12 18.6569 10.6569 20 9 20H7.82929C7.41746 21.1652 6.30622 22 5 22C3.34315 22 2 20.6569 2 19C2 17.3431 3.34315 16 5 16C6.30622 16 7.41746 16.8348 7.82929 18H9C9.55228 18 10 17.5523 10 17V14C10 13.2316 10.2889 12.5308 10.7639 12C10.2889 11.4692 10 10.7684 10 10V7C10 6.44772 9.55228 6 9 6H7.82929C7.41746 7.16519 6.30622 8 5 8C3.34315 8 2 6.65685 2 5ZM5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18ZM20 14H16V10H20V14Z"
      />
    </svg>
  );
};

export default MergeIcon;
