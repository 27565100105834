// @ts-check
import React from 'react';

const FeedbackIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M2,3 L22,3 L22,17 L17.999,17 L12,21 L12,17 L2,17 L2,3 Z M11,14 L13,14 L13,12 L11,12 L11,14 Z M11,10 L13,10 L13,6 L11,6 L11,10 Z" />
    </svg>
  );
};

export default FeedbackIcon;
