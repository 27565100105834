import React from 'react';

const DocumentPdfIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H8V23C8 23.5523 8.44772 24 9 24C9.55228 24 10 23.5523 10 23V22C11.6569 22 13 20.6569 13 19C13 17.3431 11.6569 16 10 16H9C8.44772 16 8 16.4477 8 17V20H6V4L12 4V9C12 9.55228 12.4477 10 13 10H18V12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12V8.80599C20 8.28531 19.7969 7.78515 19.434 7.41183L14.7615 2.60584C14.3849 2.21852 13.8677 2 13.3275 2H6ZM14 8V4.6917L17.2164 8H14ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14L15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12L9 12ZM11 19C11 19.5523 10.5523 20 10 20V18C10.5523 18 11 18.4477 11 19ZM14 17C14 16.4477 14.4477 16 15 16H16C17.6569 16 19 17.3431 19 19V21C19 22.6569 17.6569 24 16 24H15C14.7348 24 14.4804 23.8946 14.2929 23.7071C14.1054 23.5196 14 23.2652 14 23V17ZM16 22C16.5523 22 17 21.5523 17 21V19C17 18.4477 16.5523 18 16 18V22ZM21 16C20.4477 16 20 16.4477 20 17V20V23C20 23.5523 20.4477 24 21 24C21.5523 24 22 23.5523 22 23V21H23C23.5523 21 24 20.5523 24 20C24 19.4477 23.5523 19 23 19H22V18H23C23.5523 18 24 17.5523 24 17C24 16.4477 23.5523 16 23 16H21Z"
      />
    </svg>
  );
};

export default DocumentPdfIcon;
