import React from 'react';

const InfoCircleIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <svg ref={ref} width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path d="M12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22 C6.48,22 2,17.52 2,12 C2,6.48 6.48,2 12,2 Z M12,20 C16.41,20 20,16.41 20,12 C20,7.59 16.41,4 12,4 C7.59,4 4,7.59 4,12 C4,16.41 7.59,20 12,20 Z M11,17 L11,11 L13,11 L13,17 L11,17 Z M11,9 L11,7 L13,7 L13,9 L11,9 Z" />
      </svg>
    );
  }
);

export default InfoCircleIcon;
