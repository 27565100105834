// @ts-check
import React from 'react';

const SendIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="2.01 21 23 12 2.01 3 2 10 17 12 2 14" />
    </svg>
  );
};

export default SendIcon;
