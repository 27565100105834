import React from 'react';

const DocumentPageIcon = (props) => {
  return (
    <svg width="8" height="10" viewBox="0 0 8 10" {...props}>
      <path d="M0.5 0.5H5.5L7.5 2.5V9.5H0.5V0.5ZM1.5 1.5V8.5H6.5V2.918H5.0895V1.504L5.0855 1.5H1.5Z" />
    </svg>
  );
};

export default DocumentPageIcon;
