import React from 'react';

const StarOutlineIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M14.6667 6.16L9.87334 5.74667L8.00001 1.33334L6.12668 5.75334L1.33334 6.16L4.97334 9.31334L3.88001 14L8.00001 11.5133L12.12 14L11.0333 9.31334L14.6667 6.16ZM8.00001 10.2667L5.49334 11.78L6.16001 8.92667L3.94668 7.00667L6.86668 6.75334L8.00001 4.06667L9.14001 6.76L12.06 7.01334L9.84668 8.93334L10.5133 11.7867L8.00001 10.2667Z" />
    </svg>
  );
};

export default StarOutlineIcon;
