import React from 'react';

const DocumentSmallIcon = (props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
      <path d="M2.5 1.5H7.5L9.5 3.5V10.5H2.5V1.5ZM3.5 2.5V9.5H8.5V3.918H7.0895V2.504L7.0855 2.5H3.5Z" />
    </svg>
  );
};

export default DocumentSmallIcon;
