import React from 'react';

const VideoIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C2.34315 4 1 5.34315 1 7V17C1 18.6569 2.34315 20 4 20H15C16.6569 20 18 18.6569 18 17V15.7662L21.4855 17.8575C21.7944 18.0429 22.1792 18.0477 22.4927 17.8702C22.8062 17.6927 23 17.3603 23 17V7C23 6.63973 22.8062 6.30731 22.4927 6.1298C22.1792 5.95229 21.7944 5.95715 21.4855 6.14251L18 8.23381V7C18 5.34315 16.6569 4 15 4H4ZM21 15.2338L18 13.4338V10.5662L21 8.76619V15.2338ZM3 7C3 6.44772 3.44772 6 4 6H15C15.5523 6 16 6.44772 16 7V10V14V17C16 17.5523 15.5523 18 15 18H4C3.44772 18 3 17.5523 3 17V7ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55228 5.44772 10 6 10H9C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8H6Z"
      />
    </svg>
  );
};

export default VideoIcon;
