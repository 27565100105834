import React from 'react';

const ChevronDownUpIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24  24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70711 2.29289C6.31658 1.90237 5.68342 1.90237 5.29289 2.29289C4.90237 2.68342 4.90237 3.31658 5.29289 3.70711L11.2929 9.70711C11.6834 10.0976 12.3166 10.0976 12.7071 9.70711L18.7071 3.70711C19.0976 3.31658 19.0976 2.68342 18.7071 2.29289C18.3166 1.90237 17.6834 1.90237 17.2929 2.29289L12 7.58579L6.70711 2.29289ZM6.70711 21.7071C6.31658 22.0976 5.68342 22.0976 5.29289 21.7071C4.90237 21.3166 4.90237 20.6834 5.29289 20.2929L11.2929 14.2929C11.6834 13.9024 12.3166 13.9024 12.7071 14.2929L18.7071 20.2929C19.0976 20.6834 19.0976 21.3166 18.7071 21.7071C18.3166 22.0976 17.6834 22.0976 17.2929 21.7071L12 16.4142L6.70711 21.7071Z"
      />
    </svg>
  );
};

export default ChevronDownUpIcon;
