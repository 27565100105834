import React from 'react';

const LifebuoyIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path d="M 4.968 6.382 C 3.736 7.922 3 9.875 3 12 C 3 14.125 3.737 16.078 4.968 17.618 L 7.828 14.758 C 7.305 13.967 7 13.019 7 12 C 7 10.981 7.305 10.033 7.828 9.243 Z M 6.382 4.968 L 9.243 7.828 C 10.033 7.305 10.981 7 12 7 C 13.019 7 13.967 7.305 14.758 7.828 L 17.618 4.968 C 16.078 3.737 14.125 3 12 3 C 9.875 3 7.922 3.736 6.382 4.968 Z M 16.172 14.757 L 19.032 17.618 C 20.264 16.078 21 14.125 21 12 C 21 9.875 20.264 7.922 19.032 6.382 L 16.172 9.243 C 16.695 10.033 17 10.981 17 12 C 17 13.019 16.695 13.967 16.172 14.757 Z M 17.618 19.032 L 14.758 16.172 C 13.967 16.695 13.019 17 12 17 C 10.981 17 10.033 16.695 9.243 16.172 L 6.382 19.032 C 7.922 20.264 9.875 21 12 21 C 14.125 21 16.078 20.264 17.618 19.032 Z M 1 12 C 1 5.925 5.925 1 12 1 C 18.075 1 23 5.925 23 12 C 23 18.075 18.075 23 12 23 C 5.925 23 1 18.075 1 12 Z M 12 9 C 10.343 9 9 10.343 9 12 C 9 13.657 10.343 15 12 15 C 13.657 15 15 13.657 15 12 C 15 10.343 13.657 9 12 9 Z" />
    </svg>
  );
};

export default LifebuoyIcon;
