import React from 'react';

const KeyboardArrowRightIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="8.419 4 7 5.419 13.58 12.001 7 18.581 8.419 20 16.418 12.001" />
    </svg>
  );
};

export default KeyboardArrowRightIcon;
