import React from 'react';

const ConnectIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M15.3344,2 L21.9994,8.666 L16.0404,14.626 L14.6274,13.211 L19.1724,8.666 L15.3344,4.828 L10.7884,9.374 L9.3734,7.959 L15.3344,2 Z M2,15.3326 L7.96,9.3726 L9.374,10.7876 L4.828,15.3326 L8.667,19.1716 L13.213,14.6266 L14.627,16.0396 L8.667,21.9996 L2,15.3326 Z M10.041,15.3738 L8.627,13.9598 L13.959,8.6258 L15.373,10.0398 L10.041,15.3738 Z M18.0002,15.9995 L20.0002,15.9995 L20.0002,17.9995 L22.0002,17.9995 L22.0002,20.0005 L20.0002,20.0005 L20.0002,21.9995 L18.0002,21.9995 L18.0002,20.0005 L16.0002,20.0005 L16.0002,17.9995 L18.0002,17.9995 L18.0002,15.9995 Z" />
    </svg>
  );
};

export default ConnectIcon;
