import React from 'react';

const ClearCircleFilled = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M 12 0 C 18.628906 0 24 5.371094 24 12 C 24 18.628906 18.628906 24 12 24 C 5.371094 24 0 18.628906 0 12 C 0 5.371094 5.371094 0 12 0 Z M 15.101562 7.199219 L 11.992188 10.308594 L 8.898438 7.226562 L 7.199219 8.921875 L 10.292969 12.007812 L 7.199219 15.101562 L 8.898438 16.800781 L 11.996094 13.703125 L 15.101562 16.800781 L 16.800781 15.101562 L 13.691406 12.003906 L 16.800781 8.898438 Z M 15.101562 7.199219 " />
    </svg>
  );
};

export default ClearCircleFilled;
