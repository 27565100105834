import React from 'react';

const TagsIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6549 4.20037L13.6664 4.21191L21.1371 11.5543L21.1433 11.5604L21.1432 11.5604C22.2857 12.7028 22.2857 14.555 21.1432 15.6975L15.6975 21.1432C14.5551 22.2856 12.7028 22.2856 11.5604 21.1432L11.5543 21.1371L11.5543 21.1371L4.21211 13.6664L4.20053 13.6549C4.18881 13.6432 4.17325 13.6274 4.15424 13.6077C4.11624 13.5684 4.06433 13.5133 4.0017 13.4434C3.87667 13.3039 3.70757 13.1041 3.52052 12.8519C3.14942 12.3517 2.69163 11.6243 2.37593 10.7364C2.05886 9.84459 1.87762 8.76821 2.09535 7.59793C2.31465 6.41918 2.92512 5.21989 4.07252 4.07249C5.21992 2.92509 6.4192 2.31462 7.59795 2.0953C8.76823 1.87756 9.8446 2.05878 10.7364 2.37584C11.6243 2.69152 12.3518 3.14929 12.852 3.52038C13.1041 3.70743 13.3039 3.87652 13.4434 4.00155C13.5133 4.06418 13.5684 4.11609 13.6077 4.15409C13.6274 4.17309 13.6432 4.18866 13.6549 4.20037ZM5.48673 5.4867C6.38149 4.59195 7.22582 4.19886 7.96379 4.06156C8.71023 3.92267 9.42367 4.03177 10.0664 4.26028C10.7131 4.49018 11.2649 4.83326 11.6603 5.12662C11.856 5.2718 12.0081 5.40084 12.1086 5.49088C12.1587 5.53578 12.1955 5.57065 12.2182 5.59254C12.2295 5.60347 12.2372 5.61113 12.2413 5.61515L12.2432 5.61705L12.259 5.63296L19.7316 12.9771C20.0904 13.3387 20.0895 13.9227 19.729 14.2833L14.2833 19.729C13.9227 20.0895 13.3387 20.0904 12.9771 19.7315L5.63311 12.259L5.61719 12.2432L5.61529 12.2412C5.61275 12.2387 5.60875 12.2347 5.6034 12.2292C5.60028 12.226 5.5967 12.2223 5.59267 12.2182C5.57078 12.1955 5.53592 12.1586 5.49101 12.1085C5.40096 12.0081 5.27193 11.856 5.12674 11.6603C4.83337 11.2649 4.49028 10.713 4.26037 10.0664C4.03184 9.42363 3.92274 8.71019 4.06161 7.96375C4.19891 7.22578 4.59198 6.38146 5.48673 5.4867ZM6.93939 6.94645C6.3536 7.53224 6.3536 8.48198 6.93939 9.06777C7.52517 9.65356 8.47492 9.65356 9.06071 9.06777L9.06778 9.0607C9.65357 8.47491 9.65357 7.52516 9.06778 6.93938C8.48199 6.35359 7.53225 6.35359 6.94646 6.93938L6.93939 6.94645Z"
      />
    </svg>
  );
};

export default TagsIcon;
