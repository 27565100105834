import React from 'react';

const DocumentSecureIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7071 1.29289C11.3166 0.902369 10.6834 0.902369 10.2929 1.29289C9.90486 1.68093 8.77333 2.49027 7.36535 3.07692C5.94393 3.66917 4.47299 3.93692 3.31624 3.55132C3.01129 3.44967 2.67607 3.5008 2.41529 3.68875C2.15452 3.87671 2 4.17855 2 4.5V8C2 12.9125 3.16605 16.2589 4.88183 18.5924C6.59336 20.9201 8.77597 22.1348 10.6061 22.9191C11.1137 23.1367 11.7016 22.9015 11.9191 22.3939C12.1367 21.8863 11.9015 21.2984 11.3939 21.0809C9.72399 20.3652 7.90659 19.3299 6.49313 17.4076C5.08392 15.4911 4 12.5875 4 8V5.71164C5.45182 5.82778 6.92535 5.42692 8.13457 4.92308C9.2666 4.4514 10.2856 3.83952 11 3.30529C11.7144 3.8395 12.7334 4.45138 13.8654 4.92306C15.0746 5.42691 16.5481 5.82778 18 5.71164V6C18 6.55228 18.4477 7 19 7C19.5523 7 20 6.55228 20 6V4.5C20 4.17856 19.8455 3.87671 19.5847 3.68876C19.3239 3.50081 18.9887 3.44967 18.6838 3.55132C17.527 3.93692 16.056 3.66916 14.6346 3.07691C13.2266 2.49025 12.0951 1.68091 11.7071 1.29289ZM12 10C12 8.89543 12.8954 8 14 8H17.5576C18.1211 8 18.6584 8.23771 19.0375 8.65465L21.4798 11.3413C21.8145 11.7094 22 12.1891 22 12.6866V19C22 20.1046 21.1045 21 20 21H14C12.8954 21 12 20.1046 12 19V10ZM14 10H17V11.5C17 12.3284 17.6715 13 18.5 13H20V19H14V10Z"
      />
    </svg>
  );
};

export default DocumentSecureIcon;
