// @ts-check
import React from 'react';

const CameraFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M17.534 6H22v15H2V6h4.465L8.82 4h6.372l2.342 2zM12 18.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-8.2c1.764 0 3.2 1.436 3.2 3.2 0 1.764-1.436 3.2-3.2 3.2a3.204 3.204 0 0 1-3.2-3.2c0-1.764 1.435-3.2 3.2-3.2z" />
    </svg>
  );
};

export default CameraFilledIcon;
