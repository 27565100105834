// @ts-check
import React from 'react';

const AddSmallIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="11 6 11 10.999 6 10.999 6 12.999 11 12.999 11 18 13 18 13 12.999 17.999 12.999 17.999 10.999 13 10.999 13 6" />
    </svg>
  );
};

export default AddSmallIcon;
