import React from 'react';

const CloseSmallIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4958 4.49502C12.7691 4.22166 12.7691 3.77844 12.4958 3.50507C12.2224 3.23171 11.7792 3.23171 11.5058 3.50507L8.00078 7.0101L4.49576 3.50507C4.22239 3.23171 3.77917 3.23171 3.50581 3.50507C3.23244 3.77844 3.23244 4.22166 3.50581 4.49502L7.01083 8.00005L3.50581 11.5051C3.23244 11.7784 3.23244 12.2217 3.50581 12.495C3.77917 12.7684 4.22239 12.7684 4.49576 12.495L8.00078 8.99L11.5058 12.495C11.7792 12.7684 12.2224 12.7684 12.4958 12.495C12.7691 12.2217 12.7691 11.7784 12.4958 11.5051L8.99073 8.00005L12.4958 4.49502Z"
      />
    </svg>
  );
};

export default CloseSmallIcon;
