import React from 'react';

const EnterKeyIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8C1.89543 8 1 8.89543 1 10V22C1 23.1046 1.89543 24 3 24H21C22.1046 24 23 23.1046 23 22V2C23 0.895431 22.1046 0 21 0H13C11.8954 0 11 0.895431 11 2V8H3ZM12 8C12 8.55229 11.5523 9 11 9H3C2.44772 9 2 9.44772 2 10V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V2C22 1.44772 21.5523 1 21 1H13C12.4477 1 12 1.44772 12 2V8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10.5C18 9.94772 17.5523 9.5 17 9.5C16.4477 9.5 16 9.94772 16 10.5V14H10.4142L11.2071 13.2071C11.5976 12.8166 11.5976 12.1834 11.2071 11.7929C10.8166 11.4024 10.1834 11.4024 9.79289 11.7929L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071L9.79289 18.2071C10.1834 18.5976 10.8166 18.5976 11.2071 18.2071C11.5976 17.8166 11.5976 17.1834 11.2071 16.7929L10.4142 16H17C17.5523 16 18 15.5523 18 15V10.5Z"
      />
    </svg>
  );
};

export default EnterKeyIcon;
