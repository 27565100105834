import React from 'react';

const DocRemoveIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M5 3H15L19 7V21H5V3ZM7 5V19H17V7.836H14.179V5.008L14.171 5H7ZM9 12H15V14H9V12Z" />
    </svg>
  );
};

export default DocRemoveIcon;
