// @ts-check
import React from 'react';

const EditIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M3,17.4612 L13.219,7.2422 L16.755,10.7792 L6.536,20.9972 L3,20.9972 L3,17.4612 Z M17.4612,3 L20.9972,6.536 L18.1682,9.364 L14.6332,5.829 L17.4612,3 Z" />
    </svg>
  );
};

export default EditIcon;
