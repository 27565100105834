import React from 'react';

const NoteAltIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C8.44772 2 8 2.44772 8 3H6C4.89543 3 4 3.89543 4 5V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V5C20 3.89543 19.1046 3 18 3H16C16 2.44772 15.5523 2 15 2H9ZM16 7C16 7.55228 15.5523 8 15 8H9C8.44772 8 8 7.55228 8 7V5H6V20H18V5H16V7ZM14 6H10V4H14V6ZM8 13C8 12.4477 8.44772 12 9 12L15 12C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14L9 14C8.44772 14 8 13.5523 8 13ZM9 16C8.44772 16 8 16.4477 8 17C8 17.5523 8.44772 18 9 18H15C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16L9 16Z"
      />
    </svg>
  );
};

export default NoteAltIcon;
