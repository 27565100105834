import React from 'react';

const SharedFolderIcon = (props) => {
  return (
    <svg width="24px" height="24px" {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H12V21C12 21.4045 12.2436 21.7691 12.6173 21.9239C12.991 22.0787 13.4211 21.9931 13.7071 21.7071L17.4142 18H20C21.1046 18 22 17.1046 22 16V6C22 4.89543 21.1046 4 20 4H12.4142L10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2H4ZM4 4L9.58579 4L11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6H20V16H17C16.7348 16 16.4804 16.1054 16.2929 16.2929L14 18.5858V17C14 16.4477 13.5523 16 13 16H4V4ZM8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12H8.01C8.56228 12 9.01 11.5523 9.01 11C9.01 10.4477 8.56228 10 8.01 10H8ZM12 10H12.01C12.5623 10 13.01 10.4477 13.01 11C13.01 11.5523 12.5623 12 12.01 12H12C11.4477 12 11 11.5523 11 11C11 10.4477 11.4477 10 12 10ZM16 10C15.4477 10 15 10.4477 15 11C15 11.5523 15.4477 12 16 12H16.01C16.5623 12 17.01 11.5523 17.01 11C17.01 10.4477 16.5623 10 16.01 10H16Z"
      />
    </svg>
  );
};

export default SharedFolderIcon;
