// @ts-check
import React from 'react';

const WarningCircleIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm-1 10.999h2V7h-2v5.999zM11 17h2v-2h-2v2z" />
    </svg>
  );
};

export default WarningCircleIcon;
