// @ts-check
import React from 'react';

const SignIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M1,3 L11,3 L15,7 L15,7.836 L13,9.835 L13,7.836 L10.18,7.836 L10.18,5.008 L10.172,5 L3,5 L3,19 L13,19 L13,18.165 L15,16.164 L15,21 L1,21 L1,3 Z M18.0496,6.2004 L20.2506,3.9994 L22.9996,6.7504 L20.7996,8.9504 L18.0496,6.2004 Z M16.9492,7.3003 L19.6992,10.0503 L11.7502,18.0003 L9.0002,18.0003 L9.0002,15.2493 L16.9492,7.3003 Z M4,18 L4,16 L8,16 L8,18 L4,18 Z" />
    </svg>
  );
};

export default SignIcon;
