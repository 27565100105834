import React from 'react';

const PersonIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 8.985c-4.418 0-8 3.139-8 7.01h16c0-3.871-3.582-7.01-8-7.01zm0 2c2.457 0 4.574 1.24 5.501 3.01H6.499c.927-1.77 3.044-3.01 5.501-3.01zM12 6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z" />
    </svg>
  );
};

export default PersonIcon;
