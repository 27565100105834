// @ts-check
import React from 'react';

const WarningIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg ref={ref} width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 1.89722C11.4842 1.89722 10.9773 2.0301 10.528 2.28306C10.0791 2.53578 9.69791 2.90484 9.43191 3.34609L0.961909 17.4861L0.953786 17.4999C0.691839 17.9535 0.553236 18.4678 0.55177 18.9917C0.550303 19.5155 0.686022 20.0306 0.945426 20.4857C1.20483 20.9408 1.57887 21.32 2.03035 21.5857C2.48182 21.8513 2.99498 21.9942 3.51879 22L20.4698 22L20.4808 21.9999C21.0046 21.9941 21.5177 21.8513 21.9692 21.5857C22.4207 21.32 22.7947 20.9408 23.0541 20.4857C23.3135 20.0306 23.4493 19.5155 23.4478 18.9917C23.4463 18.4678 23.3077 17.9535 23.0458 17.4999L23.0376 17.4861L14.5677 3.34608L14.5648 3.34133C14.2974 2.90049 13.9209 2.53601 13.4716 2.28306C13.0223 2.0301 12.5154 1.89722 11.9998 1.89722ZM11.9998 8C12.552 8 12.9998 8.44772 12.9998 9V13C12.9998 13.5523 12.552 14 11.9998 14C11.4475 14 10.9998 13.5523 10.9998 13V9C10.9998 8.44772 11.4475 8 11.9998 8ZM11.9998 16C11.4475 16 10.9998 16.4477 10.9998 17C10.9998 17.5523 11.4475 18 11.9998 18H12.0098C12.562 18 13.0098 17.5523 13.0098 17C13.0098 16.4477 12.562 16 12.0098 16H11.9998Z"
      />
    </svg>
  );
});

export default WarningIcon;
