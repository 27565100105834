import React from 'react';

const InputFieldIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M13 18H20C21.1046 18 22 17.1046 22 16V8C22 6.89543 21.1046 6 20 6H13M5 18H4C2.89543 18 2 17.1046 2 16V8C2 6.89543 2.89543 6 4 6H5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 3H9M12 3H9M9 3V21M9 21H6M9 21H12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InputFieldIcon;
