import React from 'react';

const LockOutlineIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V9H8V7ZM6 9V7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7V9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V11C2 9.89543 2.89543 9 4 9H6ZM20 20V11H4V20H20ZM12 13C12.5523 13 13 13.4477 13 14V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V14C11 13.4477 11.4477 13 12 13Z"
      />
    </svg>
  );
};

export default LockOutlineIcon;
