import React from 'react';

const IncomingIcon16 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0051 2.00005C14.0051 1.61345 13.6917 1.30005 13.3051 1.30005H10.3051C9.91848 1.30005 9.60508 1.61345 9.60508 2.00005C9.60508 2.38665 9.91848 2.70005 10.3051 2.70005H12.6051V13.3H10.3051C9.91848 13.3 9.60508 13.6135 9.60508 14C9.60508 14.3866 9.91848 14.7 10.3051 14.7H13.3051C13.6917 14.7 14.0051 14.3866 14.0051 14V2.00005ZM7.00503 4.50507C7.27839 4.2317 7.72161 4.2317 7.99497 4.50507L10.995 7.50507C11.2683 7.77844 11.2683 8.22165 10.995 8.49502L7.99497 11.495C7.72161 11.7684 7.27839 11.7684 7.00503 11.495C6.73166 11.2217 6.73166 10.7784 7.00503 10.5051L8.81005 8.70005H2.00078C1.61418 8.70005 1.30078 8.38665 1.30078 8.00005C1.30078 7.61345 1.61418 7.30005 2.00078 7.30005H8.81005L7.00503 5.49502C6.73166 5.22165 6.73166 4.77844 7.00503 4.50507Z"
      />
    </svg>
  );
};

export default IncomingIcon16;
