import React from 'react';

const NoteIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M8.997 3h6v1H19v18H5V4h3.997V3zm6 4h-6V6H7v14h10V6h-2.003v1zM8 10V8h8v2H8zm0 3.994v-2h6v2H8z" />
    </svg>
  );
};

export default NoteIcon;
