import React from 'react';

const MarkAsUnReadIcon = (props) => {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" {...props}>
      <path d="M0 1H13V3H2V13H12V15.262L15.394 13H18V8H20V15H15.999L10 19V15H0V1ZM4 6.999V4.999H12V6.999H4ZM4 10.993V8.993H16V10.993H4ZM18 6C16.3431 6 15 4.65685 15 3C15 1.34315 16.3431 0 18 0C19.6569 0 21 1.34315 21 3C21 4.65685 19.6569 6 18 6Z" />
    </svg>
  );
};

export default MarkAsUnReadIcon;
