// @ts-check
import React from 'react';

const CheckSmallIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <polygon points="16.583 8 10.132 14.257 7.414 11.621 6 12.992 10.132 17 17.997 9.371" />
    </svg>
  );
};

export default CheckSmallIcon;
