import React from 'react';

const HorizontalLayoutIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H8C9.65685 21 11 19.6569 11 18V6C11 4.34315 9.65685 3 8 3H6ZM5 6C5 5.44772 5.44772 5 6 5H8C8.55228 5 9 5.44772 9 6V18C9 18.5523 8.55228 19 8 19H6C5.44772 19 5 18.5523 5 18V6ZM16 3C14.3431 3 13 4.34315 13 6V18C13 19.6569 14.3431 21 16 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H16ZM15 6C15 5.44772 15.4477 5 16 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H16C15.4477 19 15 18.5523 15 18V6Z"
      />
    </svg>
  );
};

export default HorizontalLayoutIcon;
