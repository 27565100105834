import React from 'react';

const FileeeLogo = (props) => {
  return (
    <svg viewBox="0 0 155 48" height="48" width="155" {...props}>
      <path
        d="M915.77,432.4v48h48v-48Zm29.66,6.32,3.66,2.56-10.53,1.86-.82-3.06Zm-5.22,35.37L930.1,459.79l3.55-.47,9.56,13.51Zm-2.69-15.27,3.14-.41,2.44,13.83-4.37-6.58Zm-7.61.71,1.43-3.46,5.52-1-2-11.3,2.56-3.66L940,454.53l5.52-1,.55,3.11Zm16.61-14.58-.45-2.53,3.07-.82.49,2.8Z"
        transform="translate(-915.77 -432.4)"
      />
      <g id="fileee">
        <path
          d="M1062.83,471.56c-5.06,0-8.33-3.77-8.33-9.61v-.47c0-5.65,3.27-9.45,8.14-9.45a7.53,7.53,0,0,1,5.77,2.48,10.52,10.52,0,0,1,2.37,7.18v.69h-13.43v.38c0,4.07,2.75,5.89,5.48,5.89a7,7,0,0,0,4.39-1.53l1.53,2.22A9,9,0,0,1,1062.83,471.56Zm-.19-16.8c-2.93,0-4.59,1.5-5.08,4.57l0,.3h10.28l0-.3c-.49-3.07-2.15-4.56-5.08-4.57Z"
          transform="translate(-915.77 -432.4)"
        />
        <path
          d="M1043.36,471.69c-5.14,0-8.46-3.82-8.46-9.74v-.47c0-5.73,3.32-9.58,8.27-9.58a7.66,7.66,0,0,1,5.87,2.52,10.65,10.65,0,0,1,2.4,7.26v.82H1038v.25c0,4,2.69,5.76,5.36,5.76a6.93,6.93,0,0,0,4.42-1.59l1.67,2.42A9.11,9.11,0,0,1,1043.36,471.69Zm-.19-16.8c-2.9,0-4.47,1.43-5,4.46l0,.15h10l0-.15c-.48-3-2.06-4.45-5-4.46Z"
          transform="translate(-915.77 -432.4)"
        />
        <path
          d="M1031.91,462.63v-.95c0-6.38-3.69-9.91-8.39-9.91-5,0-8.39,3.93-8.39,9.71V462c0,6.09,3.5,9.87,8.59,9.87a9.13,9.13,0,0,0,6.26-2.47l-1.81-2.63a6.79,6.79,0,0,1-4.45,1.65c-2.78,0-5.23-1.93-5.23-5.63v-.12Zm-8.39-7.6h0c3.25,0,4.44,1.91,4.83,4.35h-9.68C1019.08,456.93,1020.27,455,1023.52,455Z"
          transform="translate(-915.77 -432.4)"
        />
        <path
          d="M1011.36,467.66a2.06,2.06,0,0,1-1.59.59,1.86,1.86,0,0,1-2-1.64V441.33h-3.47v25.09a5.5,5.5,0,0,0,5.49,5.49,5.44,5.44,0,0,0,3.63-1.37Z"
          transform="translate(-915.77 -432.4)"
        />
        <g id="i">
          <rect x="79.63" y="19.35" width="3.47" height="19.72" />
          <rect x="79.63" y="10.16" width="3.47" height="4.91" />
        </g>
        <path
          d="M985.7,440.88a7.08,7.08,0,0,0-7.07,7h0v23.61h3.47V455.21H989v-3.47H982.1v-3.79a3.6,3.6,0,0,1,7.13-.7l3.42-.6A7.07,7.07,0,0,0,985.7,440.88Z"
          transform="translate(-915.77 -432.4)"
        />
      </g>
    </svg>
  );
};

export default FileeeLogo;
