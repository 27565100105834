import React from 'react';

const MessageAddIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M0 2.9997H13V4.9997H2V14.9997H12V17.2617L15.394 14.9997H18V9.9997H20V16.9997H15.999L10 20.9997V16.9997H0V2.9997ZM18 0H20V3H23V4.999H20V8H18V4.999H15V3H18V0ZM4 8.9987V6.9987H12V8.9987H4ZM4 12.9927V10.9927H16V12.9927H4Z" />
    </svg>
  );
};

export default MessageAddIcon;
