// @ts-check
import React from 'react';

const StampIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M2 20v-2h12.001v2H2zm8-7h6v4H0v-4h6.001c.001-.003.268-3.08-1.676-7.423C4.319 5.564 4.001 4.881 4 4a3.999 3.999 0 0 1 4-4 3.994 3.994 0 0 1 4 4c.004.799-.316 1.561-.32 1.567C9.732 9.915 10 12.997 10 13z"
        transform="translate(4, 0)"
      />
    </svg>
  );
};

export default StampIcon;
