// @ts-check
import React from 'react';

const ArrowRightIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12.022 4l-1.414 1.414 5.559 5.559H4v2.001h12.167l-5.559 5.558 1.414 1.414 7.973-7.973z" />
    </svg>
  );
};

export default ArrowRightIcon;
