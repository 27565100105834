import React from 'react';

function DocumentUploadIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C4 2.89543 4.89543 2 6 2H13.3275C13.8677 2 14.3849 2.21852 14.7615 2.60584L19.434 7.41183C19.7969 7.78515 20 8.28531 20 8.80599V12C20 12.5523 19.5523 13 19 13C18.4477 13 18 12.5523 18 12V10H13C12.4477 10 12 9.55231 12 9.00003V4L6 4V20H14C14.5523 20 15 20.4477 15 21C15 21.5523 14.5523 22 14 22H6C4.89543 22 4 21.1046 4 20V4ZM14 4.6917L17.2164 8.00003H14V4.6917ZM8 13C8 12.4477 8.44772 12 9 12L15 12C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14L9 14C8.44772 14 8 13.5523 8 13ZM9 18L13 18C13.5523 18 14 17.5523 14 17C14 16.4477 13.5523 16 13 16L9 16C8.44771 16 8 16.4477 8 17C8 17.5523 8.44772 18 9 18ZM21.7071 17.2929L19.7071 15.2929C19.3166 14.9024 18.6834 14.9024 18.2929 15.2929L16.2929 17.2929C15.9024 17.6834 15.9024 18.3166 16.2929 18.7071C16.6834 19.0976 17.3166 19.0976 17.7071 18.7071L18 18.4142V21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21V18.4142L20.2929 18.7071C20.6834 19.0976 21.3166 19.0976 21.7071 18.7071C22.0976 18.3166 22.0976 17.6834 21.7071 17.2929Z"
      />
    </svg>
  );
}

export default DocumentUploadIcon;
