import React from 'react';

const PencilOutlineIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        d="M15.233 5.22783L18.769 8.75671M16.733 3.73085C17.2019 3.2629 17.8378 3 18.501 3C19.1641 3 19.8001 3.2629 20.269 3.73085C20.7379 4.19881 21.0013 4.8335 21.0013 5.49529C21.0013 6.15709 20.7379 6.79177 20.269 7.25973L8.46982 19.034L3.00098 21L4.93741 15.5016L16.733 3.73085Z"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilOutlineIcon;
