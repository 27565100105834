import React from 'react';

const AddSpaceIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M11 10V8H13V10H15V12H13V14H11V12H9V10H11Z" />
      <path d="M2 2H10L12 4H22V18H17.999L12 22V18H2V2ZM4 4V16H14V18.262L17.394 16H20V6H11L9 4H4Z" />
    </svg>
  );
};

export default AddSpaceIcon;
