import React from 'react';

const PersonCircleFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12 2c5.522 0 10 4.478 10 10 0 5.523-4.478 10-10 10S2 17.523 2 12C2 6.478 6.478 2 12 2zm3.5 6.504c0-1.933-1.567-3.5-3.5-3.5s-3.5 1.567-3.5 3.5c0 1.934 1.567 3.5 3.5 3.5s3.5-1.566 3.5-3.5zm-9.865 8.321C7.097 18.75 9.402 20 12 20c2.598 0 4.903-1.25 6.365-3.175C17.263 15.013 14.83 13.75 12 13.75s-5.263 1.263-6.365 3.075z" />
    </svg>
  );
};

export default PersonCircleFilledIcon;
