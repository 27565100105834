import React from 'react';

const DashboardIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M13.1469 2.58224C12.4583 2.10019 11.5417 2.10019 10.8531 2.58224L2.85308 8.18224C2.31842 8.5565 2 9.16807 2 9.8207V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V9.8207C22 9.16808 21.6816 8.5565 21.1469 8.18224L13.1469 2.58224ZM4 9.8207L12 4.2207L20 9.8207V20H4V9.8207ZM9 16C8.44772 16 8 16.4478 8 17C8 17.5523 8.44772 18 9 18H15C15.5523 18 16 17.5523 16 17C16 16.4478 15.5523 16 15 16H9Z" />
    </svg>
  );
};

export default DashboardIcon;
