import React from 'react';

const CalendarNewIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3V4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7C2 5.34315 3.34315 4 5 4H7V3C7 2.44772 7.44772 2 8 2ZM15 6V7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7V6H19C19.5523 6 20 6.44772 20 7V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V7C4 6.44772 4.44772 6 5 6H7V7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7V6H15ZM7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H17C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10H7Z"
      />
    </svg>
  );
};

export default CalendarNewIcon;
