import React from 'react';

const PinFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6168 2.07615C11.9905 1.92137 12.4206 2.00692 12.7067 2.29293L21.7068 11.2931C21.9928 11.5791 22.0784 12.0093 21.9236 12.3829C21.7688 12.7566 21.4042 13.0003 20.9997 13.0003L18.312 13.0003L12.4995 17.0243V19.5004C12.4995 19.9049 12.2559 20.2695 11.8822 20.4243C11.5085 20.5791 11.0784 20.4935 10.7924 20.2075L7.99945 17.4146L4.70662 20.7074C4.31609 21.0979 3.68293 21.0979 3.29241 20.7074C2.90188 20.3169 2.90188 19.6837 3.2924 19.2932L6.58523 16.0004L3.79225 13.2074C3.5047 12.9198 3.41991 12.4868 3.57779 12.112C3.73567 11.7372 4.10477 11.4954 4.51141 11.5003L6.97819 11.53L10.9995 5.68915V3.00005C10.9995 2.59558 11.2432 2.23094 11.6168 2.07615Z"
      />
    </svg>
  );
};

export default PinFilledIcon;
