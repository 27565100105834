// @ts-check
import React from 'react';

const DocumentPagesIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4C8 2.89543 8.89543 2 10 2H15.3256C15.8681 2 16.3872 2.22035 16.7641 2.61052L20.4385 6.41461C20.7987 6.78749 21 7.28567 21 7.80409V16C21 17.1046 20.1046 18 19 18H17V20C17 21.1046 16.1046 22 15 22H6C4.89543 22 4 21.1046 4 20V8C4 6.89543 4.89543 6 6 6H8V4ZM19 16H17V11.8041C17 11.2857 16.7987 10.7875 16.4385 10.4146L12.7641 6.61052C12.3872 6.22035 11.8681 6 11.3256 6H10V4H15.3256L19 7.80409V16ZM12 8.69821V11H14.2233L12 8.69821ZM15 13H11C10.4477 13 10 12.5523 10 12V8L6 8V20H15V13ZM8 14C7.44772 14 7 14.4477 7 15C7 15.5523 7.44772 16 8 16L13 16C13.5523 16 14 15.5523 14 15C14 14.4477 13.5523 14 13 14L8 14ZM7 18C7 17.4477 7.44772 17 8 17L13 17C13.5523 17 14 17.4477 14 18C14 18.5523 13.5523 19 13 19H8C7.44772 19 7 18.5523 7 18Z"
      />
    </svg>
  );
};

export default DocumentPagesIcon;
