// @ts-check
import React from 'react';

const FolderSharedFilledIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12,6 L22,6 L22,20 L2,20 L2,4 L10,4 L12,6 Z M13.75225,16 L16.003,16 C16.003,14.54725 14.65975,13.3705 13.00225,13.3705 C12.796,13.3705 12.59425,13.38925 12.39925,13.423 C13.219,14.044 13.75225,14.962 13.75225,16 Z M10.0015,16 L13.00225,16 C13.00225,15.0295 12.39475,14.19025 11.5015,13.735 C11.05825,13.50925 10.5505,13.3705 10.0015,13.3705 C8.344,13.3705 7,14.54725 7,16 L10.0015,16 Z M10.0015,10 C9.17275,10 8.50075,10.672 8.50075,11.5 C8.50075,12.32875 9.17275,13.00075 10.0015,13.00075 C10.83025,13.00075 11.5015,12.32875 11.5015,11.5 C11.5015,10.672 10.83025,10 10.0015,10 Z M13.00225,10 C12.598,10 12.2335,10.16125 11.96275,10.4215 C12.142,10.74325 12.2515,11.10775 12.2515,11.5 C12.2515,11.89375 12.142,12.25825 11.9635,12.58 C12.2335,12.8395 12.598,13.00075 13.00225,13.00075 C13.83025,13.00075 14.50225,12.32875 14.50225,11.5 C14.50225,10.672 13.83025,10 13.00225,10 Z" />
    </svg>
  );
};

export default FolderSharedFilledIcon;
